import { Box } from "grommet";

export const VerticalDivider = (props) => {
    return (
        <Box
            alignSelf="center"
            width="4px"
            height="100%"
            margin={{ left: "22px", right: "22px" }}
            background={
                props.background.doted
                    ? `repeating-linear-gradient(to bottom,transparent 0 4px,${
                          props.background.color
                              ? props.background.color
                              : "black"
                      } 4px 8px) 50%/2px 100% no-repeat`
                    : `${
                          props.background.color
                              ? props.background.color
                              : "black"
                      } no-repeat`
            }
        />
    );
};
