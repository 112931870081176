import { Search } from "grommet-icons";
import { useState } from "react";
import { Box, Button, Form, TextInput } from "grommet";
import { useHistory } from "react-router-dom";
import { useResponsive } from "../responsive";
import { messagesAccueil } from "../messages/messagesAccueil";

export const Recherche = (props) => {
    const [recherche, setRecherche] = useState(props.value ? props.value : "");
    const history = useHistory();

    const goToSearch = () => {
        if (recherche && recherche.trim()) {
            history.push("/recherche/sessions?recherche=" + recherche.trim());
        } else {
            history.push("/recherche/sessions");
        }
    };

    const smallTheme = {
        formWidth: "100%",
        placeholder: messagesAccueil.SEARCH_PLACEHOLDER_SMALL,
        searchAlign: "center",
    };

    const regularTheme = {
        searchWidth: "large",
        placeholder: messagesAccueil.SEARCH_PLACEHOLDER_FULL,
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <Box
            background="light-2"
            fill="horizontal"
            flex={false}
            align="center"
            border={{
                side: "all",
                color: "border",
            }}
            elevation={"medium"}
        >
            <Form
                onSubmit={props.onSubmit ? props.onSubmit : goToSearch}
                style={{ width: responsiveTheme.formWidth }}
            >
                <Box
                    width={responsiveTheme.searchWidth}
                    direction="row-responsive"
                    gap="small"
                    align="center"
                    pad={{ horizontal: "small", vertical: "xsmall" }}
                >
                    <Box  pad={{ horizontal: "small" }} flex direction="row" align="center" background="white" round>
                        <Search color="brand" />
                        <TextInput
                            type="search"
                            textAlign={responsiveTheme.searchAlign}
                            plain
                            a11yTitle={messagesAccueil.SEARCH_PLACEHOLDER_FULL}
                            placeholder={responsiveTheme.placeholder}
                            value={props.onChange ? props.value : recherche}
                            onChange={(event) =>
                                props.onChange
                                    ? props.onChange(event.target.value)
                                    : setRecherche(event.target.value)
                            }
                            
                        />
                    </Box>
                    
                    <Button
                        type="submit"
                        label="Lancer la recherche"
                        primary
                    />
                </Box>
            </Form>
        </Box>
    );
};
