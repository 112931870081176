import { Box, Button, Grommet, Heading, Layer, Text } from "grommet";
import { useContext } from "react";
import { ErrorDispatch } from "../App";

import { messagesCommon } from "../messages/messagesCommon";
import { theme } from "../theme";

export const FatalErrorModal = (props: any) => {

    const errorDispatch = useContext(ErrorDispatch);
     
    const onDismiss = () => {
        errorDispatch({type: "close"});
        window.location.replace("/");
    };

    return (
        <Grommet theme={theme}>
            <Layer position="center">
            <Box pad="medium" gap="small" width="medium">
                <Heading level={3} margin="none">
                    {messagesCommon.FATAL_ERROR}
                </Heading>
                <Text>
                    {props.type === "401"
                        ? messagesCommon.FATAL_401
                        : messagesCommon.FATAL_UNKNOW}
                </Text>

                <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    justify="end"
                    pad={{ top: "medium", bottom: "small" }}
                >
                    <Button
                        label={messagesCommon.OK}
                        onClick={onDismiss}
                        primary
                    />
                </Box>
            </Box>
        </Layer>
        </Grommet>
        
    );
};
