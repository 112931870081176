import { Box, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { VerticalDivider } from "../components/VerticalDivider";
import { CandidatureAcceptee } from "./CandidatureAcceptee";
import { DemandeEnvoyee } from "./DemandeEnvoyee";
import { getTheme } from "../etapeThemes";
import { useResponsive } from "../../responsive";

export const Inscription = (props) => {
    const { candidature } = props;

    const inscription = (() => {

        if (!candidature.inscriptionClose && !(candidature.etapes?.length > 0)) {
            return getTheme(
                "current",
                messagesEspaceCandidat.ENCOURS,
                undefined
            );
        } else {
            return getTheme(
                "doneOK",
                messagesEspaceCandidat.CLOSE,
                undefined
            );
        }
    })();

    const smallTheme = {
        border: undefined,
    };

    const regularTheme = {
        border: { size: "small" },
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    return (
        <Box gap="small">
            <Box
                pad={{left:"medium", right:"small"}}
                border={responsiveTheme.border}
                round="medium"
                direction="row"
            >
                <Box direction="column" fill="horizontal">
                    <Box direction="row" gap="small">
                        <VerticalDivider
                            background={{
                                color: inscription.theme.dividerColor,
                                doted: false,
                            }}
                        />
                        <Box pad={{top:"small"}} direction="row" height="xxsmall" gap="medium">
                            <Text
                                textAlign="start"
                                size="xxlarge"
                                color={inscription.theme.backgroundColor}
                                weight="bold"
                            >
                                {messagesEspaceCandidat.INSCRIPTION}
                            </Text>
                            <Box alignSelf="center">
                                <Box
                                    background={inscription.theme.backgroundColor}
                                    round="small"
                                    pad="xsmall"
                                >
                                    <Text
                                        color="white"
                                        weight="bold"
                                        size="xsmall"
                                    >
                                        {inscription.header}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <DemandeEnvoyee
                        dateCreation={props.candidature.dateCreation}
                        statutDossier={props.candidature.statutDossier}
                        idSession={props.candidature.idSession}
                        cloture={candidature.inscriptionClose || candidature.etapes?.length > 0}
                    />
                    <CandidatureAcceptee
                        dateValidation={props.candidature.dateValidation}
                        statutDossier={props.candidature.statutDossier}
                        cloture={candidature.inscriptionClose || candidature.etapes?.length > 0}
                        isSelected={candidature.etapes?.length > 0}
                    />
                </Box>
            </Box>
        </Box>
    );
};
