import { Box, Text } from "grommet";

export const TableCellResponsive = (props) => {
    
    return (
        <Box direction="row" justify="between" fill="horizontal">
            <Text weight="bold">{props.header}</Text>
            <Text textAlign="end">{props.content}</Text>
        </Box>
    );
};
