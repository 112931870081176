import { Box } from "grommet";

export const LegalTexts = (props) => {

    return (
        <Box direction="row-responsive" justify="between" align="center">
            {props.labels["messageJuridique"] && (
                <ul>
                    <li>{props.labels["span_inscription3"]}</li>
                    <li>{props.labels["span_inscription2"]}</li>
                    <li>{props.labels["messageJuridique"]}</li>
                    <li>{props.labels["span_lif_saisie"]}</li>
                </ul>
            )}
        </Box>
    );
};
