import { Box, DataTable, Heading } from "grommet";
import { messagesListeSessions } from "../messages/messagesListeSessions";
import { BoutonInscription } from "./BoutonInscription";
import { BoutonInscriptionResponsive } from "./BoutonInscriptionResponsive";
import { TableCellResponsive } from "./TableCellResponsive";
import { useResponsive, useScreenSize } from "../responsive";
import "./datatable.css";

export const ResultatsRecherche = (props) => {
    const smallTheme = {
        padding: {
            top: "small",
            bottom: "large",
        },
        cellBorder: {
            body: {
                color: "transparent",
                side: "bottom",
            },
        },
    };

    const regularTheme = {
        padding: {
            right: "xlarge",
            left: "xlarge",
            top: "small",
            bottom: "large",
        },
        dateSize: "xsmall",
        cellBorder: {
            header: "bottom",
            body: {
                color: "brand",
                side: "bottom",
            },
            footer: "top",
        },
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    const screenSize = useScreenSize();

    const columns: any = [
        {
            header: messagesListeSessions.ADMINISTRATION_EMPLOI,
            property: "administration",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                              key={"adm" + datum.tsessionId}
                              header={
                                  messagesListeSessions.ADMINISTRATION_EMPLOI
                              }
                              content={datum.administration}
                          />
                      ),
        },
        {
            header: messagesListeSessions.RECRUTEMENT,
            property: "fullRecrutement",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"rec" + datum.tsessionId}
                              header={messagesListeSessions.RECRUTEMENT}
                              content={datum.fullRecrutement}
                          />
                      ),
        },
        {
            header: messagesListeSessions.SPECIALITE,
            property: "specialite",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"spe" + datum.tsessionId}
                              header={messagesListeSessions.SPECIALITE}
                              content={datum.specialite}
                          />
                      ),
        },
        {
            header: messagesListeSessions.BUREAU_RECRUTEMENT,
            property: "bureauRecrutement",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"bur" + datum.tsessionId}
                              header={messagesListeSessions.BUREAU_RECRUTEMENT}
                              content={datum.bureauRecrutement}
                          />
                      ),
        },
        {
            header: messagesListeSessions.OUVERTURE_INSCRI,
            property: "ouvertureInscriptionInternetFormatted",
            size: responsiveTheme.dateSize,
            align: "center",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"ouv" + datum.tsessionId}
                              header={messagesListeSessions.OUVERTURE_INSCRI}
                              content={datum.ouvertureInscriptionInternet}
                          />
                      ),
        },
        {
            header: messagesListeSessions.CLOTURE_INSCRI,
            property: "limiteDepotDossierInternetFormatted",
            size: responsiveTheme.dateSize,
            align: "center",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"limite" + datum.tsessionId}
                              header={messagesListeSessions.CLOTURE_INSCRI}
                              content={datum.limiteDepotDossierInternet}
                          />
                      ),
        },
        {
            header: messagesListeSessions.DATE_PREMIER_INSCRI,
            property: "datePremiereEpreuveFormatted",
            size: responsiveTheme.dateSize,
            align: "center",
            render:
                screenSize !== "small"
                    ? null
                    : (datum) => (
                          <TableCellResponsive
                          key={"epr" + datum.tsessionId}
                              header={messagesListeSessions.DATE_PREMIER_INSCRI}
                              content={datum.datePremiereEpreuve}
                          />
                      ),
        },
        {
            render: (datum) =>
                screenSize !== "small" ? (
                    <BoutonInscription session={datum} />
                ) : (
                    <BoutonInscriptionResponsive session={datum} />
                ),
            header: "Actions",
            property: "tsessionId",
            border: responsiveTheme.cellBorder,
            align: "center",
        },
    ];

    const defaultSort: any = {
        property: "limiteDepotDossierInternet",
        direction: "asc",
    };

    return (
        <Box fill pad={responsiveTheme.padding}>
            <Heading level="3" alignSelf="center">
                Résultats
            </Heading>
            <DataTable
                id="searchResults"
                columns={columns}
                data={props.resultats}
                primaryKey="tsessionId"
                step={10}
                sortable
                border={responsiveTheme.cellBorder}
                sort={defaultSort}
                background={{
                    header: "brand",
                }}
            />
        </Box>
    );
};
