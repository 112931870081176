export const messagesInscriptionSession = {
    PAGE_TITLE: "Inscription à une session",
    ADMINISTRATION_EMPLOI: "Administration d'emploi",
    RECRUTEMENT: "Recrutement",
    FIN_INSCRIPTION: "Inscription cloturée le",
    ETAT_CIVIL: "Etat civil",
    AUTRE_RENS: "Autres renseignements",
    OPTIONS: "Options",
    COMPETENCES: "Compétences",
    VOEUX: "Voeux",
    PIECES_A_JOINDRE: "Pièces à joindre",
    PIECES_JOINTES: "Pièces jointes",
    CIVILITE: "Civilité",
    NOM_NAISS: "Nom de naissance",
    NOM_MARITAL: "Nom marital",
    PRENOM_USUEL: "Prénom usuel",
    ANNULATION_MODAL: "Etes-vous sûr de vouloir supprimer votre formulaire et revenir à la page de recherche ?",
    SAVE_ERROR_MODAL: "Vous ne pouvez pas enregistrer votre formulaire avant d'avoir renseigné votre nom et prénom.",
    CONNAISSANCE_DROITS: "J'ai bien pris connaissance de mes droits",
    CONNAISSANCE_DROITS_VALIDATION: "J'ai bien pris connaissance de mes droits et je valide",
    BACK_TO_FORM: "Revenir au formulaire",
    TOAST_SAVE_ERROR: "Une erreur est survenue durant l'enregistrement.",
    TOAST_SAVE_SUCCESS: "L'enregistrement a été effectué avec succès.",
    TOAST_SUBMIT_ERROR: "Une erreur est survenue durant la soumission du dossier.",
    INSCRIPTION_NON_AUTORISEE: "L'inscription est impossible pour cette session ou vous n'êtes pas connecté.",
    INSCRIPTION_DEJA_VALIDE: "L'inscription est impossible car vous avez déjà validé un dossier pour cette session.",
    BACK_SESSIONS: "Retour à la liste des sessions",
    EPREUVES_OBL: "Epreuves obligatoires",
    EPREUVES_FAL: "Epreuves facultatives",
    CHOIX_EPR: "Choix des épreuves",
    VOEUX_AFFECT: "Voeux d'affectation",
    VOEU_NUM: "Voeu n°",
    VOEU_OBL: " (Obligatoire)",
    VOEU_FAL: " (Facultatif)",
    PJ_MAXSIZE_ERROR: "Vous ne pouvez pas joindre des fichiers ayant une taille supérieure à $size Mo",
    PJ_FILE_REJECTED: "Vous n'êtes pas autorisé à joindre ce type de fichier",
    DOWNLOAD_EXAMPLE: "Télécharger le modèle",
    NEED_TO_SAVE_DOSSIER: "Vous devez enregistrer votre inscription avant de pouvoir téléverser des pièces jointes",
    SELECT_BUREAU_RECRUTEMENT: "Choix d'un bureau de rattachement de votre dossier",
    GO_ESPACE_CANDIDAT: "Accéder à mon espace candidat",
    DOSSIER_VALIDE_HEADER: "Dossier validé le DATE à HEURE",
    INFO_SESSION_END: "Pour que l'administration puisse examiner votre demande d'inscription, vous devrez la valider avant la date indiquée",
    INVALID_POSTAL_CODE: "Code postal invalide",
    REQUIRED_DIGITS: " chiffres requis",
    BIRTHDATE_ERROR: "Votre date de naissance est incorrecte pour vous inscrire.",
    DATE_INVALID: "Date invalide",
    OPTIONAL_DOCUMENT: " (facultatif)",
    NO_DOCUMENT_UPLOADED: "Aucune pièce jointe fournie",
    YEAR_MORE_THAN_ZERO: "Le champ doit avoir une valeur supérieure à 0",
    ERROR_LIMIT_POSTCODE: "Votre adresse de domicile ne permet pas d'enregistrer votre candidature dans cette région de recrutement ({0}). Le bureau d'inscription pour votre adresse est : {1}.",
    SESSION_CONFLICT_1: "Vous ne pouvez pas demander une inscription à ce concours :",
    SESSION_CONFLICT_2: "car vous avez déjà enregistré une demande pour le concours :",
    SESSION_CONFLICT_DELETE: "Voulez-vous supprimer votre inscription au concours de {0} ?",
    SESSION_CONFLICT_CONTACT: "Le cas échéant, vous pouvez contacter le bureau responsable du concours pour lequel vous avez validé une demande d'inscription.",
    SESSION_INCOMPAT_1: "Attention, si vous validez une demande d'inscription à ce concours :",
    SESSION_INCOMPAT_2: "Vous ne pourrez pas ensuite valider d'inscription pour le(s) concours suivant(s) :",
    SESSION_INCOMPAT_CONTINUE: "Continuer",
    SESSION_INCOMPAT_STOP: "Arrêter",
    SESSION_CONFLICT_DELETE_BTN: "Supprimer",
    SESSION_CONFLICT_NO_DELETE_BTN: "Ne pas supprimer",
    ACKNOWLEDGE_OPTIONAL_TESTS: "J’ai bien pris connaissance des épreuves facultatives qui me sont proposées"
};