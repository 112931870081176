import { Box, Button, Markdown, Text } from "grommet";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { store } from "../auth/store";
import { messagesCommon } from "../messages/messagesCommon";
import { paths } from "../paths";
import { AccountUpdate } from "./AccountUpdate";

export const Connected = (props: any) => {
    const history = useHistory();

    const [openUpdate, setOpenUpdate] = useState(false);

    const onEspaceCandidatClick = () => {
        history.push(paths.ESPACE_CANDIDAT);
    };

    const onUpdateAccountClick = () => setOpenUpdate(true);
    const onUpdateAccountClose = (hasChanged: boolean) => {
        setOpenUpdate(false);
        if (hasChanged) {
            //On force le rechargement de toute la page pour actualiser les comportements liés au fait d'être connecté ou pas
            history.go(0);
        }
    };

    return (
        <Box direction="column" gap="medium">
            <Box
                pad={{ left: "small", right: "small" }}
                direction="column"
                alignSelf="center"
            >
                <Text size="xlarge" textAlign="center">
                    {messagesCommon.WELCOME}
                </Text>
                <Text size="xlarge" textAlign="center">
                    <Markdown>{`**${store.prenom} ${store.nom}**`}</Markdown>
                </Text>
            </Box>
            <Box pad={{ left: "large", right: "large" }}>
                {props.isEspaceCandidat && (
                    <Button
                        label={messagesCommon.UPDATE_ACCOUNT}
                        margin={{ top: "small" }}
                        onClick={onUpdateAccountClick}
                        primary
                    />
                )}
                {!props.isEspaceCandidat && (
                    <Button
                        label={messagesCommon.ESPACE_CANDIDAT}
                        margin={{ top: "small" }}
                        onClick={onEspaceCandidatClick}
                        primary
                    />
                )}

                <Button
                    label={messagesCommon.DECONNEXION}
                    margin={{ top: "small" }}
                    onClick={props.disconnect}
                    primary
                />
            </Box>
            {openUpdate && <AccountUpdate onClose={onUpdateAccountClose} />}
        </Box>
    );
};
