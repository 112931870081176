import { paths } from "../paths";

export const agent = {
    getRefCorps: (callback) => {
        fetch(paths.API_CORPS)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            callback(data.content);
        })
    },
    getRefTexteByCode: (callback, code) => {
        fetch(`${paths.API_TEXT_FINDBYCODE}?code=${code}`)
        .then((response) => {
            return response.json()
        }, error => {
            console.log(error);
        })
        .then((data) => {
            callback(data);
        })
    },
};
