import { Box, Text } from "grommet";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { Notification } from "./Notification";

export const Notifications = (props) => {
    const getCandidatureForNotification = (idSession) => {
        return props.candidatures.find((c) => {
            return c.idSession === idSession;
        });
    };

    return (
        <Box align="center">
            <Box
                direction="column"
                pad={{
                    left: "medium",
                    top: "small",
                    bottom: "small",
                    right: "medium",
                }}
                margin={{ top: "medium", bottom: "medium" }}
                background={{ color: "sicminotifbloc" }}
                width="100%"
                round="xsmall"
                gap="small"
            >
                <Text size="xlarge" color="white" weight="bold">
                    {messagesEspaceCandidat.NOTIFICATIONS}
                </Text>
                {props.notifications?.length > 0 &&
                    props.candidatures?.length > 0 &&
                    props.notifications.slice(0, 3).map(function (notif, i) {
                        return (
                            <Notification
                                key={i}
                                notification={notif}
                                last={
                                    (props.notifications.length < 3 &&
                                        i === 1) ||
                                    i === 2
                                }
                                onlyChild={props.notifications.length === 1}
                                candidature={getCandidatureForNotification(
                                    notif.sessionId
                                )}
                            />
                        );
                    })}
                {(!props.candidatures ||
                    !props.notifications ||
                    props.notifications.length === 0) && (
                    <Text color="white">
                        {messagesEspaceCandidat.NO_NOTIFS}
                    </Text>
                )}
            </Box>
        </Box>
    );
};
