import { Box, Text } from "grommet";
import { useEffect, useState } from "react";
import { config } from "../config";
import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";

import { LocaleDate } from "./components/LocaleDate";
import { listeNotifications } from "./listeNotifications";

export const Notification = (props) => {

    const [notification, setNotification] = useState("");

    useEffect(() => {
        let notifTemp = listeNotifications[props.notification.code];
        notifTemp = notifTemp.replace("$GRADE", props.candidature?.gradeRecrutSession);

        if (props.notification.etapeId && props.candidature?.etapes?.length > 0) {
            const etape = props.candidature?.etapes?.find(e => e.idEtape === props.notification.etapeId);
            notifTemp = notifTemp.replace("$ETAPE", etape ? etape.nomEtape : "");
        }

        if (notifTemp.includes("$dateEpreuve") && props.notification.dateCreation) {
            let dateNotif: Date = new Date(props.notification.dateCreation);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
            dateNotif.setDate(dateNotif.getDate() + config.DAYS_BEFORE_EPREUVE);
            notifTemp = notifTemp.replace("$dateEpreuve", dateNotif.toLocaleDateString(undefined, options));
        }
        
        setNotification(notifTemp);
    }, [props]);

    return (
        <Box border={props.last === false && props.onlyChild === false ? { color: "sicminotiffade", side: "bottom"} : undefined} pad={{bottom: "small"}}>
                <Text color="white">
                    <LocaleDate date={props.notification.dateCreation} locale="fr-FR" weight="bold" textPrefix={messagesEspaceCandidat.DATE_PREFIXE} />
                </Text>
                <Text color="white">
                    {notification}
                </Text>
        </Box>
    );
};
