import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
} from "grommet";

import { FormClock } from "grommet-icons";

import "leaflet/dist/leaflet.css";
import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { useScreenSize } from "../../responsive";

export const Epreuves = (props) => {
    const headers = [
        { id: "c1", label: messagesEspaceCandidat.DATE_EPREUVE },
        { id: "c2", label: messagesEspaceCandidat.NOM_EPREUVE },
        { id: "c3", label: messagesEspaceCandidat.TYPE_EPREUVE },
        { id: "c4", label: messagesEspaceCandidat.DUREE_EPREUVE },
        { id: "c5", label: messagesEspaceCandidat.COEFFICIENT_EPREUVE },
        {
            id: "c6",
            label: props.affichageNotes
                ? messagesEspaceCandidat.NOTE_AVIS
                : messagesEspaceCandidat.ADRESSE,
        },
    ];

    function timeConvert(n) {
        let num = n;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        if (rhours > 0) {
            let result = rhours + "h";
            return rminutes > 0 ? result + rminutes : result;
        }
        return rminutes + " min";
    }

    const screenSize = useScreenSize();

    function getTableCellContent(content, header) {
        if (screenSize === "small") {
            return (
                <Box direction="row" justify="between" fill="horizontal">
                    <Text weight="bold">{header}</Text>
                    {content}
                </Box>
            );
        }

        return content;
    }

    function getAvisLabel(avis) {
        switch(avis) {
            case 1:
                return messagesEspaceCandidat.FAVORABLE;
            case 2:
                return messagesEspaceCandidat.DEFAVORABLE;
            default:
                return avis;
        }
    }

    return (
        <Box>
            <Table>
                <TableHeader>
                    <TableRow>
                        {headers.map((c) => (
                            <TableCell key={c.id} scope="col">
                                <Text>{c.label}</Text>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {props.epreuves.map((datum) => (
                        <TableRow key={datum.id}>
                            <TableCell>
                                {getTableCellContent(
                                    <Text
                                        weight="bold"
                                        size="small"
                                        color="black"
                                    >
                                        {datum.debutEpreuve && new Date(
                                            datum.debutEpreuve
                                        ).toLocaleDateString()}
                                    </Text>,
                                    messagesEspaceCandidat.DATE_EPREUVE_SHORT
                                )}
                            </TableCell>
                            <TableCell>
                                {getTableCellContent(
                                    <Text
                                        size="small"
                                        weight="bold"
                                        color="sicmiblue"
                                    >
                                        {datum.nomEpreuve}
                                    </Text>,
                                    messagesEspaceCandidat.NOM_EPREUVE_SHORT
                                )}
                            </TableCell>
                            <TableCell>
                                {getTableCellContent(
                                    <Text
                                        size="xsmall"
                                        weight="bold"
                                        color="black"
                                    >
                                        {datum.typeEpreuve}
                                    </Text>,
                                    messagesEspaceCandidat.TYPE_EPREUVE_SHORT
                                )}
                            </TableCell>
                            <TableCell>
                                {getTableCellContent(
                                    <Box direction="row" align="center">
                                        {datum.dureeMinutes && (
                                            <>
                                                <FormClock
                                                    role="img"
                                                    color="black"
                                                />
                                                <Text
                                                    size="small"
                                                    weight="bold"
                                                    color="black"
                                                >
                                                    {timeConvert(
                                                        datum.dureeMinutes
                                                    )}
                                                </Text>
                                            </>
                                        )}
                                    </Box>,
                                    messagesEspaceCandidat.DUREE_EPREUVE_SHORT
                                )}
                            </TableCell>
                            <TableCell>
                                {getTableCellContent(
                                    <Text
                                        size="small"
                                        weight="bold"
                                        color="black"
                                    >
                                        {datum.coefficient
                                            ? `COEF ${datum.coefficient}`
                                            : undefined}
                                    </Text>,
                                    messagesEspaceCandidat.COEFFICIENT_EPREUVE_SHORT
                                )}
                            </TableCell>
                            <TableCell>
                                {props.affichageNotes
                                    ? getTableCellContent(
                                          <Text
                                              size="small"
                                              weight="bold"
                                              color="black"
                                          >
                                              {datum.elimSansNote &&
                                                  messagesEspaceCandidat.ELIMINE}
                                              {!datum.elimSansNote &&
                                                  (datum.note
                                                      ? datum.note
                                                      : getAvisLabel(datum.avis))}
                                          </Text>,
                                          messagesEspaceCandidat.NOTE_AVIS
                                      )
                                    : getTableCellContent(
                                          <Box>
                                              <Text
                                                  size="small"
                                                  weight="bold"
                                                  color="black"
                                              >
                                                  {datum.libelle}
                                              </Text>
                                              <Text
                                                  size="small"
                                                  weight="bold"
                                                  color="black"
                                              >
                                                  {datum.adresse}
                                              </Text>
                                              <Text
                                                  size="small"
                                                  weight="bold"
                                                  color="black"
                                              >
                                                  {datum.adresse2}
                                              </Text>
                                              <Text
                                                  size="small"
                                                  weight="bold"
                                                  color="black"
                                              >
                                                  {datum.codePostal}{" "}
                                                  {datum.ville}
                                              </Text>
                                          </Box>,
                                          messagesEspaceCandidat.ADRESSE
                                      )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};
