import { Heading, Text } from "grommet";

import { ListFormField } from "./inputs/ListFormField";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";

export const VoeuxAffectation = (props) => {
    const genererListeVoeux = () => {
        const result = [];

        for (let i = 1; i <= props.paramVoeux.nbVoeuxTotal; i++) {

            // Il ne doit pas être possible de sélectionner plusieurs fois le même voeu.
            const disabled = [];
            for (let j = 1; j <= props.paramVoeux.nbVoeuxTotal; j++) {
                if (i != j) {
                    const voeu = props.formulaire["voeu"+j];
                    if (voeu) {
                        disabled.push(voeu);
                    }
                }
            }

            let required = false;
            let libelle = "";
            if (i <= props.paramVoeux.nbVoeuxObligatoire) {
                required = true;
                libelle =
                    messagesInscriptionSession.VOEU_NUM +
                    i +
                    messagesInscriptionSession.VOEU_OBL;
            } else {
                libelle =
                    messagesInscriptionSession.VOEU_NUM +
                    i +
                    messagesInscriptionSession.VOEU_FAL;
            }

            result.push(
                <ListFormField
                    name={"voeu" + i}
                    key={i}
                    label={libelle}
                    required={required}
                    options={props.voeuxPossibles}
                    labelKey="libelleDpt"
                    valueKey="trefVoeuxAffectionId"
                    disabled={disabled}
                />
            );
        }

        return result;
    };
    return props.paramVoeux &&
        props.voeuxPossibles &&
        props.voeuxPossibles.length > 0 ? (
        <>
            <Heading
                level={2}
                margin={{ bottom: "medium", top: "none" }}
                textAlign="center"
                fill
            >
                {messagesInscriptionSession.VOEUX_AFFECT}
            </Heading>
            <Text
                textAlign="center"
                margin={{ bottom: "1em" }}
                style={{ fontStyle: "italic" }}
            >
                {props.paramVoeux?.instructions}
            </Text>
            {genererListeVoeux()}
        </>
    ) : null;
};
