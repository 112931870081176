import { Box, Button, Form, Layer, Paragraph, Text } from "grommet";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { EtatCivil } from "./EtatCivil";
import { AutresRenseignements } from "./AutresRenseignements";
import { Options } from "./Options";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { agentInscriptions } from "./agentInscriptions";
import { SpinnerWithText } from "../common/SpinnerWithText";
import { paths } from "../paths";
import { BoutonsInscription } from "./BoutonsInscription";
import { Toast } from "../common/Toast";
import { prepareOptionsForSave } from "./InscriptionSession";
import { useResponsive } from "../responsive";
import { PiecesAJoindre } from "./PiecesAJoindre";
import { ErrorDispatch } from "../App";
import { FormulaireTabs } from "./FormulaireTabs";
import { store } from "../auth/store";
import { messagesCommon } from "../messages/messagesCommon";
import { config } from "../config";
import { formCodes } from "./formCodes";
import { LegalTexts } from "./LegalTexts";

export const formulaireDefault = {
    dossierId: -1,
    versionFormulaire: 0,
    civilite: "",
    nom: "",
    prenom: "",
    nomMarital: "",
    autresPrenoms: "",
    adresse: "",
    compAdresse: "",
    commune: "",
    codePostal: "",
    pays: "",
    dateNaiss: "",
    lieuNaiss: "",
    nationalite: "",
    paysNaiss: "",
    depNaiss: "",
    telephone: "",
    situationFamiliale: "",
    nbrEnfants: "",
    nbrEnfantsACharge: "",
    nbrPersonnesHandicapeesACharge: "",
    profession: "",
    texteTelpro: "",
    texteEmailpro: "",
    situationMilitaire: "",
    engagementSousDrapeauxNbrAnnees: "",
    engagementSousDrapeauxNbrMois: "",
    diplome: "",
    diplomeEtat: "",
    expProfessionnel: "",
    nomPere: "",
    professionPere: "",
    nomMere: "",
    professionMere: "",
    personneHandicapee: "",
    amenagementDemandeExt: "",
    travaileurHandicape: "",
    ancienTravaileurHandicape: "",
    travaileurHandicapeNbrAnnee: "",
    sportifHautNiveau: "",
    ancienSportifHautNiveau: "",
    sportifHautNiveauNbrAnnee: "",
    ccCondpart: "",
    rbLbl1: "",
    rbLbl2: "",
    rbLbl3: "",
    rbLbl4: "",
    nombreLbl5: "",
    nombreLbl6: "",
    dateLbl7: "",
    dateLbl8: "",
    nombreLbl9: "",
    nombreLbl10: "",
    texteLbl11: "",
    texteLbl12: "",
    aucunLbl13: "",
    aucunLbl14: "",
    lastModified: undefined,
};

export const transformPropertyKey = (propertyKey) => {
    const parts = propertyKey.toLowerCase().split("_");

    if (parts.length > 1) {
        let finalKey = parts[0];

        for (let i = 1; i < parts.length; i++) {
            finalKey =
                finalKey + parts[i][0].toUpperCase() + parts[i].substr(1);
        }

        return finalKey;
    } else {
        return parts;
    }
};

export const useStateWithLocalStorage = (localStorageKey) => {
    const [value, setValue] = useState(
        JSON.parse(localStorage.getItem(localStorageKey)) || formulaireDefault
    );

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value, localStorageKey]);

    return [value, setValue];
};

export const FormulaireInscription = (props) => {
    const errorDispatch = useContext(ErrorDispatch);

    const [etatCivilValide, setEtatCivilValide] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [autresRensValide, setAutresRensValide] = useState(true);
    const [optionsValide, setOptionsValide] = useState(true);
    const [piecesJointesValide, setPiecesJointesValide] = useState(true);
    const [referentiel, setReferentiel] = useState({} as any);
    const [autresRensChamps, setAutresRensChamps] = useState([]);
    const [competenceChamps, setCompetenceChamps] = useState([]);
    const [afficherModal, setAfficherModal] = useState(false);
    const [afficherSaveError, setAfficherSaveError] = useState(false);
    const [toastSaveErrorCount, setToastSaveErrorCount] = useState(0);
    const [toastSaveCustomErrorCount, setToastSaveCustomErrorCount] =
        useState(0);
    const [toastSaveCustomErrorMessage, setToastSaveCustomErrorMessage] =
        useState(undefined);
    const [toastSaveSuccessCount, setToastSaveSuccessCount] = useState(0);
    const [loadingRef, setLoadingRef] = useState(false);
    const [loadingAr, setLoadingAr] = useState(false);
    const [loadingComp, setLoadingComp] = useState(false);
    const [loadingSavedDossier, setLoadingSavedDossier] = useState(false);
    const [formulaireValide, setFormulaireValide] = useState(null);
    const [currentOnglet, setCurrentOnglet] = useState(0);
    const [deleteError, setDeleteError] = useState(false);
    const [displayOnglets, setDisplayOnglets] = useState([
        true,
        false,
        false,
        false,
        false,
    ]);
    const [uploadedFiles, setUploadedFiles] = useState(undefined);

    const [labels, setLabels] = useState(undefined);

    const [formulaire, setFormulaire] = useStateWithLocalStorage(
        config.SICMI_FORM_PREFIX + props.idSession + store.compte_id
    );

    const champEcRequis = {
        civilite: true,
        nom: true,
        prenom: true,
        adresse: true,
        commune: true,
        codePostal: true,
        pays: true,
        dateNaiss: true,
        lieuNaiss: true,
        paysNaiss: true,
        depNaiss: true,
        nationaliteEnCours: true,
    };

    const upperCaseFields = [
        formCodes.NOM,
        formCodes.PRENOM,
        formCodes.NOM_MARITAL,
        formCodes.AUTRES_PRENOMS,
        formCodes.LIEU_NAISSANCE,
        formCodes.ADRESSE_1,
        formCodes.ADRESSE_2,
        formCodes.COMMUNE,
        formCodes.NOM_MERE,
        formCodes.NOM_PERE,
        formCodes.ADRESSE_AFFECTATION,
        formCodes.ADRESSE_AFFECTATION_2,
        formCodes.AFFECTATION_ADMIN,
        formCodes.AFFECTATION_ADMIN_2,
        formCodes.COMMUNE_AFFECTATION,
    ];

    const notRequiredIfNotFrance = [
        formCodes.CODE_POSTAL_AFFECTATION,
        formCodes.COMMUNE_AFFECTATION,
    ];

    const [champsArRequis, setChampsArRequis] = useState({});

    const [champsArCaseCocher, setChampsArCaseCocher] = useState({});

    const history = useHistory();

    const smallTheme = {
        formWidth: "100%",
    };

    const regularTheme = {
        formWidth: "60%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    useEffect(() => {
        if (firstLoad) {
          
        setLoadingAr(true);
        setLoadingRef(true);
        setLoadingComp(true);

        setLoadingSavedDossier(true);
        agentInscriptions.getDossier(
            props.idSession,
            (dossier) => {
                if (dossier.lastModified) {
                    const savedDossierDateModif = new Date(
                        dossier.lastModified
                    );
                    if (
                        !(
                            formulaire.lastModified &&
                            savedDossierDateModif <
                                new Date(formulaire.lastModified)
                        ) ||
                        formulaire.versionFormulaire === undefined ||
                        formulaire.versionFormulaire < dossier.versionFormulaire
                    ) {
                        for (const obj of Object.entries(dossier)) {
                            if (obj[1] === null) {
                                dossier[obj[0]] = "";
                            }
                        }

                        if (dossier.epreuvesObligatoires?.length > 0) {
                            dossier.epreuvesObligatoires.forEach((epr) => {
                                if (epr.selectedOptionId) {
                                    dossier["eprObl" + epr.tepreuveId] = {
                                        toptionEpreuveId: epr.selectedOptionId,
                                        tepreuveId: epr.tepreuveId,
                                    };
                                } else {
                                    dossier["eprObl" + epr.tepreuveId] = true;
                                }
                            });
                        }
                        if (dossier.epreuvesFacultatives?.length > 0) {
                            dossier.epreuvesFacultatives.forEach((epr) => {
                                if (epr.selectedOptionId) {
                                    dossier["eprFal" + epr.tepreuveId] = {
                                        toptionEpreuveId: epr.selectedOptionId,
                                        tepreuveId: epr.tepreuveId,
                                    };
                                } else {
                                    dossier["eprFal" + epr.tepreuveId] = true;
                                }
                            });
                        }

                        if (dossier.choixVoeux?.length > 0) {
                            dossier.choixVoeux.forEach((voeu) => {
                                const ordre = voeu.ordre+1;
                                dossier["voeu"+ordre] = voeu;
                            });
                        }
                        setFormulaire(dossier);
                    }
                } else if (
                    formulaire.versionFormulaire === undefined ||
                    (dossier?.versionFormulaire &&
                        dossier.versionFormulaire >
                            formulaire.versionFormulaire)
                ) {
                    formulaire.versionFormulaire = dossier.versionFormulaire;
                    setFormulaire({
                        versionFormulaire: dossier.versionFormulaire,
                        ...formulaireDefault,
                    });
                }
                setLoadingSavedDossier(false);
            },
            (error) => {
                setLoadingSavedDossier(false);
                console.log(error);
            }
        );

        agentInscriptions.getInscriptionReferentiel(
            (content) => {
                content.pays = content.pays
                    .sort((a, b) => a.libelle.localeCompare(b.libelle))
                    .filter((pays) => pays.valide === 1);
                setReferentiel(content);
                setLoadingRef(false);
            },
            (error) => {
                errorDispatch({ type: error });
            },
            props.idSession
        );

        agentInscriptions.getInscriptionLabels((content) => {
            setLabels(content);
        }, props.idSession);

        agentInscriptions.getAutresRenseignementsChamps(
            props.idSession,
            (content) => {
                let autresRensReq = {};
                let autresRensCasesCocher = {};
                content
                    .filter(
                        (champ) =>
                            champ.choisirAffichage === true &&
                            champ.obligatoireSurInternet === true
                    )
                    .forEach((champ) => {
                        // On futurproofe dans le cas où d'autres cases à cocher seraient ajoutées plus tard.
                        // On veut remplir ce tableau afin de savoir plus tard si 0 n'est pas une valeur acceptable 
                        // lors de la validation du formulaire.
                        if (champ.type === "CASE_A_COCHER") {
                            autresRensCasesCocher[champ.codeForm] = true;
                        }

                        autresRensReq[champ.codeForm] = true;
                    });
                setChampsArRequis(autresRensReq);
                setChampsArCaseCocher(autresRensCasesCocher);
                setAutresRensChamps(
                    content
                        .sort((a, b) => (a.ordre > b.ordre ? 1 : -1))
                        .filter(
                            (champ) =>
                                champ.choisirAffichage === true &&
                                champ.tRefOngletInternetId !== 50
                        )
                );
                setLoadingAr(false);
            },
            (error) => {
                errorDispatch({ type: error });
            }
        );

        agentInscriptions.getCompetencesChamps(
            props.idSession,
            (competences) => {
                setCompetenceChamps(competences);
                setLoadingComp(false);
            },
            (error) => {
                errorDispatch({ type: error });
            }
        );
        setFirstLoad(false);
    }
    }, [firstLoad, setFirstLoad, errorDispatch, formulaire, props.idSession, setFormulaire]);

    useEffect(() => {
        if (!loadingAr && !loadingRef && !loadingSavedDossier) {
            let modified = false;
            if (!formulaire.nom) {
                formulaire.nom = store.nom;
                modified = true;
            }
            if (!formulaire.prenom) {
                formulaire.prenom = store.prenom;
                modified = true;
            }
            if (modified) {
                setFormulaire({ ...formulaire });
            }
        }
    }, [loadingAr, loadingRef, loadingSavedDossier, formulaire, setFormulaire]);

    const checkErrors = () => {
        let result = validateEtatCivil();
        result = validateAutresRens();
        if (referentiel.epreuvesObligatoires?.length > 0) {
            result = validateOptions();
        }
        if (referentiel.piecesAJoindre?.length > 0) {
            result = validatePiecesJointes();
        }
        return result;
    };

    const onClickEnregistrer = (e) => {
        let data = JSON.parse(
            localStorage.getItem(
                config.SICMI_FORM_PREFIX + props.idSession + store.compte_id
            )
        );

        if (data !== undefined && data.nom !== "" && data.prenom !== "") {
            data = trimData(data);
            data = prepareOptionsForSave(
                data,
                referentiel.epreuvesObligatoires,
                referentiel.epreuvesFacultatives
            );
            agentInscriptions.enregistrerDossier(
                (content) => {
                    if (content.idDossier) {
                        formulaire["dossierId"] = content.idDossier;
                        setFormulaire({ ...formulaire });
                        setToastSaveSuccessCount(toastSaveSuccessCount + 1);
                    } else {
                        setToastSaveCustomErrorMessage(content.error);
                        setToastSaveCustomErrorCount(
                            toastSaveCustomErrorCount + 1
                        );
                    }
                },
                (error) => {
                    console.log(error);
                    setToastSaveErrorCount(toastSaveErrorCount + 1);
                },
                props.idSession,
                data
            );
        } else {
            setAfficherSaveError(true);
        }
    };

    const validateEtatCivil = () => {
        let ecOk = true;
        for (const obj of Object.entries(champEcRequis)) {
            if (!formulaire[obj[0]]) {
                if (
                    (obj[0] === formCodes.CODE_POSTAL ||
                        obj[0] === formCodes.COMMUNE) &&
                    formulaire[formCodes.PAYS]?.code !== "033"
                ) {
                    continue;
                }
                if (
                    obj[0] === formCodes.DEPARTEMENT_NAISSANCE &&
                    formulaire[formCodes.PAYS_NAISSANCE]?.code !== "033"
                ) {
                    continue;
                }

                if (
                    obj[0] === formCodes.NATIONALITE_EN_COURS &&
                    formulaire[formCodes.NATIONALITE]?.code === "033"
                ) {
                    continue;
                }

                console.log("Le champ '" + obj[0] + "' n'a pas été rempli");
                ecOk = false;
                break;
            }
        }

        if (!ecOk) {
            console.log("Erreur dans l'onglet Etat Civil du formulaire", champEcRequis);
        }

        setEtatCivilValide(ecOk);
        return ecOk;
    };

    function validatePiecesJointes() {
        if (
            referentiel.piecesAJoindre &&
            referentiel.piecesAJoindre.length > 0
        ) {
            let pjOk = false;
            let temp = referentiel.piecesAJoindre.filter((p) => !p.facultative);
            if (
                temp.length === 0 ||
                (uploadedFiles && uploadedFiles.length >= temp.length)
            ) {
                pjOk = true;
            }

            if (!pjOk) {
                console.log("Erreur dans l'onglet Pièces à joindre du formulaire");
            }

            setPiecesJointesValide(pjOk);
            return pjOk;
        }
        return true;
    }

    const formatOptions = (eprObl, eprFal) => {
        let optOk = true;
        if (
            referentiel.epreuvesObligatoires &&
            referentiel.epreuvesObligatoires.length > 0
        ) {
            for (const epr of referentiel.epreuvesObligatoires) {
                if (!formulaire["eprObl" + epr.tepreuveId]) {
                    optOk = false;
                    break;
                }
            }
        }
        setOptionsValide(optOk);
    };

    const validateOptions = () => {
        let optOk = true;
        if (
            referentiel.epreuvesObligatoires &&
            referentiel.epreuvesObligatoires.length > 0
        ) {
            for (const epr of referentiel.epreuvesObligatoires) {
                if (!formulaire["eprObl" + epr.tepreuveId]) {
                    optOk = false;
                    break;
                }
            }
        }
        if (
            optOk &&
            referentiel.epreuvesFacultatives &&
            referentiel.epreuvesFacultatives.length > 0
        ) {
            optOk = formulaire["okEprFal"] === true;
        }

        if (!optOk) {
            console.log("Erreur dans l'onglet Options du formulaire");
        }

        setOptionsValide(optOk);
        return optOk;
    };

    const validateAutresRens = () => {
        let arOk = true;
        for (const obj of Object.entries(champsArRequis)) {
            // Ces champs n'ont aucune valeur possible donc on skip
            if (obj[0].startsWith(formCodes.AUCUN_LBL)) {
                continue;
            }

            // On vérifie les cases à cocher
            if (champsArCaseCocher[obj[0]] && !formulaire[obj[0]]) {
                console.log("Erreur case à cocher", obj[0]);
                arOk = false;
                break;
            }

            if (obj[0] === "listeDiraffectpn") {
                if (
                    formulaire["listeAdminemp"] &&
                    formulaire["listeAdminemp"].code === "ZZ" &&
                    !formulaire[obj[0]]
                ) {
                    console.log("Erreur direction affectation", obj[0]);
                    arOk = false;
                    break;
                }
            } else if (formulaire[obj[0]] === undefined || formulaire[obj[0]] === null || formulaire[obj[0]] === "") {
                if (
                    (obj[0] === formCodes.PROFESSION_PERE &&
                        formulaire[formCodes.NOM_PERE] === "X") ||
                    (obj[0] === formCodes.PROFESSION_MERE &&
                        formulaire[formCodes.NOM_MERE] === "X")
                ) {
                    continue;
                }
                if (
                    notRequiredIfNotFrance.includes(obj[0]) &&
                    formulaire[formCodes.PAYS_AFFECTATION]?.code !== "033"
                ) {
                    continue;
                }
                if (
                    (obj[0] === formCodes.AMENAGEMENT_DEMANDE ||
                        obj[0] === formCodes.AMENAGEMENT_DEMANDE_EXT) &&
                    formulaire[formCodes.PERSONNE_HANDICAPEE] !== 1
                ) {
                    continue;
                }

                if (
                    obj[0] === formCodes.ETAT_DIPLOME &&
                    formulaire[formCodes.DIPLOME]?.code === "SANS"
                ) {
                    continue;
                }

                console.log("Le champ '" + obj[0] + "' n'a pas été rempli");
                arOk = false;
                break;
            }
        }

        if (arOk) {
            if (
                formulaire[formCodes.ANCIEN_SPORTIF_HAUT_NIVEAU] === true &&
                (formulaire[formCodes.SPORTIF_HAUT_NIVEAU_ANNEE] ===
                    undefined ||
                    formulaire[formCodes.SPORTIF_HAUT_NIVEAU_ANNEE] === "")
            ) {
                console.log("Erreur sportif haut niveau");
                arOk = false;
            }

            if (
                formulaire[formCodes.ANCIEN_TRAVAILLEUR_HANDICAPE] === true &&
                (formulaire[formCodes.TRAVAILLEUR_HANDICAPE_ANNEE] ===
                    undefined ||
                    formulaire[formCodes.TRAVAILLEUR_HANDICAPE_ANNEE] === "")
            ) {
                console.log("Erreur travailleur handicapé");
                arOk = false;
            }
        }

        if (!arOk) {
            console.log("Erreur dans l'onglet Autres Renseignements du formulaire", champsArRequis);
        }

        setAutresRensValide(arOk);
        return arOk;
    };

    const tabValidation = [
        validateEtatCivil,
        validateAutresRens,
        validateOptions,
        validateOptions,
        validatePiecesJointes,
    ];

    // Alors appris à la dur : on ne modifie pas le formulaire dans la méthode de validation
    // Sinon, ca rerender le formulaire, et ca redéclenche une validation
    // Par exemple si j'essaye de trim les strings ici, l'utilisateur ne pourra plus
    // Saisir d'espaces
    const validateForm = (validationResults) => {
        let formulaireOk = true;
        //De mémoire, j'ai codé cette boucle dégueue car des fois, validationResults.errors contient des clés
        //de certains champs avec une valeur vide et en fait y a pas d'erreur
        for (const [key, value] of Object.entries(validationResults.errors)) {

            //Mantis 7195
            if (key === formCodes.PROFESSION_MERE && formulaire[formCodes.NOM_MERE] === "X") {
                continue;
            }
            if (key === formCodes.PROFESSION_PERE && formulaire[formCodes.NOM_PERE] === "X") {
                continue;
            }

            if (key !== undefined) {
                console.log("Erreur de validation sur le champ " + key);
                formulaireOk = false;
            }
        }
        //checkErrors(validationResults.errors);
        setFormulaireValide(formulaireOk);
    };

    const onClickSupprimer = () => {
        setDeleteError(false);
        setAfficherModal(true);
    };

    const trimData = (data) => {
        for (const [key, value] of Object.entries(data)) {
            if (
                value &&
                typeof value === "string" &&
                (value.startsWith(" ") ||
                    value.endsWith(" ") ||
                    value.match(/\s{2,}/g))
            ) {
                data[key] = value.replace(/\s+/g, " ").trim();
            }
        }
        return data;
    };

    const formSubmit = (e) => {
        if (e.nativeEvent.submitter.id !== "btnForm") {
            let data = JSON.parse(
                localStorage.getItem(
                    config.SICMI_FORM_PREFIX + props.idSession + store.compte_id
                )
            );
            const noErrors = checkErrors();
            if (!noErrors) {
                setToastSaveCustomErrorMessage(
                    "Votre dossier n'a pas pu être validé, merci de corriger les erreurs indiquées"
                );
                setToastSaveCustomErrorCount(toastSaveCustomErrorCount + 1);
            } else if (
                data !== undefined &&
                data.nom !== "" &&
                data.prenom !== ""
            ) {
                data = trimData(data);
                data = prepareOptionsForSave(
                    data,
                    referentiel.epreuvesObligatoires,
                    referentiel.epreuvesFacultatives
                );

                agentInscriptions.enregistrerDossier(
                    (content) => {
                        if (!content.idDossier) {
                            setToastSaveCustomErrorMessage(content.error);
                            setToastSaveCustomErrorCount(
                                toastSaveCustomErrorCount + 1
                            );
                        } else {
                            history.push({
                                pathname: paths.INSCRIPTION_SESSION_FIN.replace(
                                    ":idSession",
                                    props.idSession
                                ),
                                state: {
                                    epreuvesObligatoires:
                                        referentiel.epreuvesObligatoires &&
                                        referentiel.epreuvesObligatoires
                                            .length > 0
                                            ? referentiel.epreuvesObligatoires
                                            : "absent",
                                    epreuvesFacultatives:
                                        referentiel.epreuvesFacultatives &&
                                        referentiel.epreuvesFacultatives
                                            .length > 0
                                            ? referentiel.epreuvesFacultatives
                                            : "absent",
                                },
                            });
                        }
                    },
                    (error) => {
                        console.log(error);
                        setToastSaveErrorCount(toastSaveErrorCount + 1);
                    },
                    props.idSession,
                    data
                );
            } else {
                setAfficherSaveError(true);
            }
        }
    };

    const onModalValiderAnnulation = (e) => {
        agentInscriptions.supprimerDossier(
            () => {
                localStorage.removeItem(
                    config.SICMI_FORM_PREFIX + props.idSession + store.compte_id
                );
                history.push(paths.LISTE_SESSIONS);
            },
            (errCode) => {
                if (errCode === "404") {
                    localStorage.removeItem(
                        config.SICMI_FORM_PREFIX +
                            props.idSession +
                            store.compte_id
                    );
                    history.push(paths.LISTE_SESSIONS);
                } else {
                    setDeleteError(true);
                }
            },
            props.idSession
        );
    };

    const onTabChange = (e) => {
        if (e !== currentOnglet) {
            displayOnglets[currentOnglet] = false;
            displayOnglets[e] = true;
            if (currentOnglet < tabValidation.length) {
                tabValidation[currentOnglet]();
            }
            setCurrentOnglet(e);
            setDisplayOnglets(displayOnglets);
        }
    };

    // Besoin de passer certains champs en uppercase
    // Tout en supprimant les accents
    const convertToUpperCase = (form: any) => {
        for (const [key, value] of Object.entries(form)) {
            if (
                upperCaseFields.includes(key) &&
                value &&
                typeof value === "string"
            ) {
                form[key] = form[key].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }
        }

        setFormulaire(form);
    };

    return (
        <Box align="center">
            <Box
                direction="column"
                pad={{ top: "small", bottom: "small" }}
                margin={{ top: "medium", bottom: "medium" }}
                width={responsiveTheme.formWidth}
                gap="small"
            >
                <Toast
                    count={toastSaveErrorCount}
                    status="status-error"
                    text={messagesInscriptionSession.TOAST_SAVE_ERROR}
                    timeout={5000}
                />
                <Toast
                    count={toastSaveCustomErrorCount}
                    status="status-error"
                    text={toastSaveCustomErrorMessage}
                    timeout={10000}
                />
                <Toast
                    count={toastSaveSuccessCount}
                    status="status-ok"
                    text={messagesInscriptionSession.TOAST_SAVE_SUCCESS}
                    timeout={10000}
                />
                {Object.keys(referentiel).length > 0 &&
                !loadingRef &&
                !loadingAr &&
                !loadingComp &&
                !loadingSavedDossier ? (
                    <Box gap="small">
                        <FormulaireTabs
                            onTabChange={onTabChange}
                            etatCivilValide={etatCivilValide}
                            autresRensValide={autresRensValide}
                            optionsValide={optionsValide}
                            piecesJointesValide={piecesJointesValide}
                            displayAutresRens={
                                autresRensChamps && autresRensChamps.length
                            }
                            displayCompetences={
                                competenceChamps && competenceChamps.length
                            }
                            displayPiecesJointes={
                                referentiel.piecesAJoindre &&
                                referentiel.piecesAJoindre.length > 0
                            }
                            displayOptions={
                                (referentiel.epreuvesObligatoires &&
                                    Object.keys(
                                        referentiel.epreuvesObligatoires
                                    ).length > 0) ||
                                (referentiel.epreuvesFacultatives &&
                                    Object.keys(
                                        referentiel.epreuvesFacultatives
                                    ).length > 0) ||
                                (referentiel.paramVoeux &&
                                    referentiel.voeuxPossibles &&
                                    referentiel.voeuxPossibles.length > 0)
                            }
                        />

                        <Form
                            id="formulaireInscription"
                            messages={{
                                invalid: "Invalide",
                                required: "Champ requis ou invalide",
                            }}
                            value={formulaire}
                            validate="blur"
                            onChange={(nextValue) => {
                                /*if (formulaireValide === false) {
                                    setFormulaireValide(true);
                                }*/
                                if (
                                    formulaire[
                                        formCodes.ADMINISTRATION_EMPLOI
                                    ] !==
                                    nextValue[formCodes.ADMINISTRATION_EMPLOI]
                                ) {
                                    nextValue[formCodes.DIRECTION_AFFECTATION] =
                                        ""; // Mantis 6471 RG-OFDA-MOD2-001-02
                                }
                                nextValue.lastModified = new Date();
                                convertToUpperCase(nextValue);
                            }}
                            onReset={() => setFormulaire(formulaireDefault)}
                            onSubmit={formSubmit}
                            onValidate={(validationResults) =>
                                validateForm(validationResults)
                            }
                        >
                            <Box
                                style={{
                                    display: displayOnglets[0]
                                        ? "flex"
                                        : "none",
                                }}
                            >
                                <EtatCivil
                                    idSession={props.idSession}
                                    noAcquisition={props.noAcquisition}
                                    listePays={referentiel.pays}
                                    listePaysAcquisition={
                                        referentiel.nationaliteAcquisitionList
                                    }
                                    listeDepartements={referentiel.departements}
                                    formulaire={formulaire}
                                    paramSaisie={referentiel.paramSaisie}
                                    conditions={referentiel.conditionsSession}
                                    labels={labels}
                                    couverture={
                                        referentiel.couvertureDepartements
                                    }
                                />
                            </Box>

                            {autresRensChamps && autresRensChamps.length > 0 && (
                                <Box
                                    style={{
                                        display: displayOnglets[1]
                                            ? "flex"
                                            : "none",
                                    }}
                                >
                                    <AutresRenseignements
                                        idSession={props.idSession}
                                        champs={autresRensChamps}
                                        referentiel={referentiel}
                                        formulaire={formulaire}
                                        labels={labels}
                                    />
                                </Box>
                            )}
                            {/*competenceChamps && competenceChamps.length > 0 && (
                                <Box
                                    style={{
                                        display: displayOnglets[2]
                                            ? "flex"
                                            : "none",
                                    }}
                                >
                                    <Competences
                                        idSession={props.idSession}
                                        champs={competenceChamps}
                                        referentiel={referentiel}
                                    />
                                </Box>
                                )*/}
                            {((referentiel.epreuvesObligatoires &&
                                Object.keys(referentiel.epreuvesObligatoires)
                                    .length > 0) ||
                                (referentiel.epreuvesFacultatives &&
                                    Object.keys(
                                        referentiel.epreuvesFacultatives
                                    ).length > 0) ||
                                (referentiel.paramVoeux &&
                                    referentiel.voeuxPossibles &&
                                    referentiel.voeuxPossibles.length > 0)) && (
                                <Box
                                    style={{
                                        display: displayOnglets[3]
                                            ? "flex"
                                            : "none",
                                    }}
                                >
                                    <Options
                                        idSession={props.idSession}
                                        eprObl={
                                            referentiel.epreuvesObligatoires
                                        }
                                        eprFal={
                                            referentiel.epreuvesFacultatives
                                        }
                                        paramVoeux={referentiel.paramVoeux}
                                        voeuxPossibles={
                                            referentiel.voeuxPossibles
                                        }
                                        formulaire={formulaire}
                                    />
                                </Box>
                            )}
                            {referentiel.piecesAJoindre &&
                                referentiel.piecesAJoindre.length > 0 && (
                                    <Box
                                        style={{
                                            display: displayOnglets[4]
                                                ? "flex"
                                                : "none",
                                        }}
                                    >
                                        <PiecesAJoindre
                                            idSession={props.idSession}
                                            piecesAJoindre={
                                                referentiel.piecesAJoindre
                                            }
                                            idDossier={formulaire.dossierId}
                                            maxSize={referentiel.pjMaxSize}
                                            allowedExtensions={
                                                referentiel.pjAllowedExtensions
                                            }
                                            isValid={piecesJointesValide}
                                            uploadedFiles={uploadedFiles}
                                            setUploadedFiles={setUploadedFiles}
                                            messagePj={props.messagePj}
                                        />
                                    </Box>
                                )}

                            <button
                                id="btnForm"
                                type="submit"
                                style={{ display: "none" }}
                            />
                            {labels && (
                                <LegalTexts
                                    idSession={props.idSession}
                                    labels={labels}
                                />
                            )}
                            <BoutonsInscription
                                clickSupprimer={onClickSupprimer}
                                clickEnregistrer={onClickEnregistrer}
                                formulaireValide={formulaireValide}
                            />
                        </Form>

                        {afficherModal && (
                            <Layer
                                onEsc={() => setAfficherModal(false)}
                                onClickOutside={() => setAfficherModal(false)}
                                animation="fadeIn"
                                modal={true}
                            >
                                <Box
                                    direction="column"
                                    margin="medium"
                                    gap="medium"
                                >
                                    <Text>
                                        {
                                            messagesInscriptionSession.ANNULATION_MODAL
                                        }
                                    </Text>
                                    {deleteError && (
                                        <Text color="red">
                                            {messagesCommon.BACKEND_ERROR}
                                        </Text>
                                    )}

                                    <Box direction="row" justify="between">
                                        <Button
                                            label={messagesCommon.ANNULER}
                                            onClick={() =>
                                                setAfficherModal(false)
                                            }
                                        />
                                        <Button
                                            primary
                                            label={messagesCommon.CONFIRMER}
                                            onClick={onModalValiderAnnulation}
                                        />
                                    </Box>
                                </Box>
                            </Layer>
                        )}
                        {afficherSaveError && (
                            <Layer
                                onEsc={() => setAfficherSaveError(false)}
                                onClickOutside={() =>
                                    setAfficherSaveError(false)
                                }
                                animation="fadeIn"
                                modal={true}
                            >
                                <Box direction="column" margin="medium">
                                    <Paragraph>
                                        {
                                            messagesInscriptionSession.SAVE_ERROR_MODAL
                                        }
                                    </Paragraph>
                                    <Box direction="row" justify="center">
                                        <Button
                                            label="Valider"
                                            onClick={() =>
                                                setAfficherSaveError(false)
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Layer>
                        )}
                    </Box>
                ) : (
                    <SpinnerWithText texte="Chargement" />
                )}
            </Box>
        </Box>
    );
};
