import { store } from "../auth/store";
import { paths } from "../paths";
import { agentCandidat } from "./agentCandidat";

export const downloadUrl = (path: string, params: any) => {
    let url = "";
    if (store.connected === true) {
        params.token = store.token;
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        url += paths.DEV_BACKEND_URL;
    }
    url += path + "?" + agentCandidat.encodeParams(params);
    return url;
};
