import { injectCsrfHeader } from "../auth/header";
import { paths } from "../paths";

export const agentFranceConnect = {
    getAuthorizeUrl: (callback) => {
        fetch(paths.API_FRANCE_CONNECT_AUTHORIZE, {
            method: "GET"
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            return response;
        })
        .then((data) => {
            callback(data);
        })
    },

    connexion: (values, alreadyAskedEmail, callback, errorCallback) => {
        fetch(paths.API_FRANCE_CONNECT_CONNEXION, {
            method: "POST",
            headers: injectCsrfHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(values)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.json();
        })
        .then((data) => {
            callback(data);
        }).catch((error) => {
            if (error.message === "412" && !alreadyAskedEmail) {
                errorCallback(true);
            } else {
                errorCallback(false);
            }
        });
    }
};