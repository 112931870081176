import { Box, Button, CheckBox, List } from "grommet";
import { useContext, useEffect, useState } from "react";

import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { paths } from "../paths";
import { agentInscriptions } from "./agentInscriptions";
import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { ErrorDispatch } from "../App";
import { useResponsive } from "../responsive";

export const PageDroits = (props) => {
    const errorDispatch = useContext(ErrorDispatch);

    const smallTheme = {
        width: "100%",
        direction: "column",
        gap: "large"
    };

    const regularTheme = {
        width: "60%",
        direction: "row",
        gap: undefined
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    const [droits, setDroits] = useState([]);
    const [accepteDroits, setAccepteDroits] = useState(false);

    useEffect(() => {
        agentInscriptions.getInscriptionDroits(
            (content) => setDroits(content),
            (error) => {
                console.log(error);
                errorDispatch({ type: error });
            }
        );
    }, []);

    const returnPath = paths.INSCRIPTION_SESSION.replace(
        ":idSession",
        props.idSession
    );

    return (
        <Box align="center">
            <Box
                direction="column"
                pad={{ top: "small", bottom: "small" }}
                margin={{ top: "medium", bottom: "medium" }}
                width={responsiveTheme.width}
                gap="small"
            >
                {droits.length > 0 && (
                    <List border={false} data={droits}>
                        {(datum) => (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        datum.charAt(0).toUpperCase() +
                                        datum.slice(1),
                                }}
                            />
                        )}
                    </List>
                )}
                <Box direction={responsiveTheme.direction} align="center" gap={responsiveTheme.gap} justify="between">
                    <Box
                        direction="row"
                        justify="start"
                        margin={{ top: "medium" }}
                    >
                        <RoutedAnchor
                            label={messagesInscriptionSession.BACK_TO_FORM}
                            to={returnPath}
                        />
                    </Box>
                    <Box
                        direction="row-responsive"
                        gap="medium"
                        justify="end"
                        margin={{ top: "medium" }}
                    >
                        <CheckBox
                            label={
                                messagesInscriptionSession.CONNAISSANCE_DROITS
                            }
                            checked={accepteDroits}
                            onChange={(event) =>
                                setAccepteDroits(event.target.checked)
                            }
                        />
                        <Button
                            type="button"
                            label={
                                messagesInscriptionSession.CONNAISSANCE_DROITS_VALIDATION
                            }
                            primary
                            disabled={!accepteDroits}
                            onClick={() => props.onAccept()}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
