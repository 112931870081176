/**
 * Fetch en GET avec un tableau de headers sous la forme :
 * {header: '', value: ''}
 * Exemple : {header: 'Authorization', value: 'Basic aaaabbbbcccc'}
 */
export async function fetchWithHeaders(url, callback, headers) {
    let _headers = {};
    if (headers) {
      headers.forEach(h => {
        _headers[h.header] = h.value;
      });
    }
    const f = await fetch(url, {
      method: "GET",
      headers: _headers,
      mode: "cors",
    });
    const blob = await f.blob();
    callback(blob);
};
