import { Grommet} from "grommet";
import { useLocation } from "react-router-dom";

import { messagesListeSessions } from "../messages/messagesListeSessions";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { ListeSessionsContent } from "./ListeSessionsContent";
import { tag } from "../common/ATInternetService";
import { useEffect } from "react";

export const ListeSessions = () => {

    const urlParams = new URLSearchParams(useLocation().search);

    const params = {
        codeCorps: urlParams.get("codeCorps"),
        codeCateg: urlParams.get("Categ"),
        codeAdmin: urlParams.get("Admin"),
        codeGrade: urlParams.get("Grade"),
        codeComp: urlParams.get("Comp"),
        codeSpec: urlParams.get("Spec"),
        codeBureau: urlParams.get("Buro"),
        recherche: urlParams.get("recherche")
    }

    useEffect(() => {
        tag.sendPage({
            name: 'Liste_sessions',
            level1: 'Liste_sessions'
        }); 
    }, []);
    
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Liste des sessions" },
    ];

    return (
        <Grommet full theme={theme}>
            <HeaderSicmi
                title={messagesListeSessions.PAGE_TITLE}
                breadcrumbs={breadcrumbs}
            />

            <ListeSessionsContent params={params} />

            <FooterSicmi />
        </Grommet>
    );
};
