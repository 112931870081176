import { useEffect, useState } from "react";
import { ListFormField } from "./ListFormField";

/**
 * Cas particulier de l'administration d'emploi.
 * Si l'administration d'emploi est positionée sur "Non sélectionné"
 * alors on doit afficher le champ de la direction d'affectation
 *
 * @param props
 * @returns
 */
export const DirectionAffectation = (props: any) => {

    const [options, setOptions] = useState(undefined);

    useEffect(() => {
        // Mantis 6471 RG-OFDA-MOD2-002-01 et RG-OFDA-MOD2-002-02
        if (props.origineFonct && props.origineFonct.trefAdministration && props.origineFonct.trefAdministration.trefAdministrationId > 0) {
            setOptions(props.direction.filter(dir => dir.trefOrigineFonct.trefOrigineFonctId === props.origineFonct.trefOrigineFonctId));
        } else if (props.origineFonct && props.origineFonct.trefAdministration && props.origineFonct.trefAdministration.trefAdministrationId === 0) {
            setOptions(props.direction.filter(dir => dir.trefOrigineFonct.trefOrigineFonctId === 0));
        } else {
            setOptions(undefined);
        }

    }, [props.direction, props.origineFonct]);

    if (options === undefined || options.length === 0) {
        return null;
    }
    
    return (
        <ListFormField
            name={props.codeForm}
            label={props.label}
            italicLabel={props.italicLabel}
            required={props.required}
            options={options}
            labelKey="libelle"
            valueKey="trefDirectionId"
        />
    );
};
