import { Box, Grommet, Main } from "grommet";
import "leaflet/dist/leaflet.css";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { EspaceCandidatBody } from "./EspaceCandidatBody";

export const EspaceCandidat = () => {
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Espace candidat" },
    ];

    return (
        <Grommet theme={theme}>
            <Box overflow="auto">
                <HeaderSicmi
                    title={messagesEspaceCandidat.PAGE_TITLE}
                    breadcrumbs={breadcrumbs}
                    isEspaceCandidat={true}
                />
                <Main>
                    <EspaceCandidatBody />
                </Main>
            </Box>
            <FooterSicmi />
        </Grommet>
    );
};
