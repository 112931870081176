import { useEffect, useState } from "react";
import { Box, Button, Heading, Text } from "grommet";
import "leaflet/dist/leaflet.css";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { Notifications } from "./Notifications";
import { Candidature } from "./Candidature";
import { useResponsive } from "../responsive";
import { messagesCommon } from "../messages/messagesCommon";
import { agentCandidat } from "./agentCandidat";
import { config } from "../config";
import { RattachementModal } from "./RattachementModal";

export const EspaceCandidatBody = () => {
    const [afficherRattachement, setAfficherRattachement] = useState(false);
    const [candidatures, setCandidatures] = useState(undefined);
    const [notifications, setNotifications] = useState(undefined);
    const [idCanditatureOuverte, setIdCandidatureOuverte] = useState(-1);
    const [errorLoading, setErrorLoading] = useState(false);

    useEffect(() => {
        if (!config.ALLOWED_FILE_TYPES) {
            agentCandidat.getPieceAdminConfig((data) => {
                config.ALLOWED_FILE_TYPES = data.accept;
                config.PIECE_ADMIN_MAXSIZE_BYTES = data.maxSize;
            });
        }

        agentCandidat.getCandidatures(
            (result) => {
                if (result !== null && result !== false) {
                    setCandidatures(result.candidatures);
                    setNotifications(result.notifications);

                    if (
                        result.candidatures &&
                        result.candidatures.length === 1
                    ) {
                        setIdCandidatureOuverte(0);
                    }
                } else if (result === false) {
                    setCandidatures(false);
                }
            },
            (error) => {
                console.log(error);
                setErrorLoading(true);
            }
        );
    }, []);

    const smallTheme = {
        candidaturesWidth: "100%",
        rattachementBtnWidth: "70%",
        titleAlign: "center"
    };

    const mediumTheme = {
        candidaturesWidth: "90%",
        rattachementBtnWidth: "30%",
        titleAlign: "start"
    };

    const regularTheme = {
        candidaturesWidth: "60%",
        rattachementBtnWidth: "30%",
        titleAlign: "start"
    };

    function toggleCandidature(index) {
        if (idCanditatureOuverte === index) {
            setIdCandidatureOuverte(-1);
        } else {
            setIdCandidatureOuverte(index);
        }
    }

    const responsiveTheme = useResponsive(
        smallTheme,
        regularTheme,
        mediumTheme
    );
    return (
        <Box align="center">
            <Box
                direction="column"
                pad={{ top: "xsmall", bottom: "xsmall" }}
                margin={{ top: "xsmall", bottom: "xsmall" }}
                width={responsiveTheme.candidaturesWidth}
                round="xsmall"
                gap="small"
            >
                <RattachementModal
                    afficher={afficherRattachement}
                    setAfficherRattachement={setAfficherRattachement}
                />
                <Notifications
                    candidatures={candidatures}
                    notifications={notifications}
                />
                <Box
                    direction="column"
                    pad={{ bottom: "xsmall" }}
                    margin={{ bottom: "small" }}
                    width={responsiveTheme.rattachementBtnWidth}
                    alignSelf="center"
                    gap="small"
                >
                    <Button
                        type="button"
                        label={messagesEspaceCandidat.RATTACHEMENT}
                        primary
                        margin={{ top: "small" }}
                        onClick={() => setAfficherRattachement(true)}
                    />
                </Box>

                <Text textAlign={responsiveTheme.titleAlign} size="xxlarge" weight="bold">
                    {messagesEspaceCandidat.CANDIDATURES}
                </Text>
                {candidatures &&
                    candidatures.map(function (candidature, i) {
                        return (
                            <Candidature
                                candidature={candidature}
                                key={i}
                                mapIndex={i}
                                unique={candidatures.size === 1}
                                open={i === idCanditatureOuverte}
                                toggleCandidature={toggleCandidature}
                            />
                        );
                    })}
                {candidatures === undefined && !errorLoading && (
                    <Box
                        align="center"
                        justify="center"
                        direction="row"
                        gap="small"
                    >
                        <Box
                            direction="row"
                            border={[
                                {
                                    side: "all",
                                    color: "transparent",
                                    size: "medium",
                                },
                                {
                                    side: "horizontal",
                                    color: "brand",
                                    size: "medium",
                                },
                            ]}
                            pad="small"
                            round="full"
                            animation={{
                                type: "rotateRight",
                                duration: 1500,
                            }}
                        />
                        <Text weight="bold">{messagesCommon.LOADING}</Text>
                    </Box>
                )}
                {candidatures === false && (
                    <Heading alignSelf="center">
                        {messagesEspaceCandidat.NO_CANDIDATURE}
                    </Heading>
                )}
                {errorLoading && (
                    <Heading alignSelf="center">
                        {messagesCommon.BACKEND_ERROR}
                    </Heading>
                )}
            </Box>
        </Box>
    );
};
