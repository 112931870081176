import { FormField, MaskedInput } from "grommet";
import { messagesInscriptionSession } from "../../messages/messagesInscriptionSession";
import { formCodes } from "../formCodes";

import { FormFieldLabel } from "./FormFieldLabel";

export const NumberFormField = (props) => {
    const {
        label,
        italicLabel,
        required,
        name,
        length,
        placeholder,
        info,
        ...rest
    } = props;

    const finalRegex = props.maxLength
        ? new RegExp("^[0-9]{1," + props.maxLength + "}$")
        : new RegExp("^[0-9]+$");

    function validateNumber(value) {
        /**
         * Cas en erreur :
         *   - Si le champ n'est PAS requis, qu'il a une longueur requise,
         *     qu'il y a une valeur et que la longueur de cette valeur ne valide pas la longueur requise
         *   - Si le champ est requis, qu'il a une longueur requise, qu'il n'a pas de valeur ou que
         *     cette valeur ne valide pas la longueur requise
         */
        if (
            (!required &&
                props.requiredLength &&
                value &&
                value.length !== props.requiredLength) ||
            (required && props.requiredLength &&
                (!value || value.length !== props.requiredLength))
        ) {
            return (
                props.requiredLength +
                messagesInscriptionSession.REQUIRED_DIGITS
            );
        }

        if (name === formCodes.ENGAGEMENT_DRAPEAUX_MOIS) {
            if (value < 0 || value > 12) {
                return "Nombre de mois invalide";
            }
        }

        if (
            props.required &&
            (name === formCodes.SPORTIF_HAUT_NIVEAU_ANNEE ||
                name === formCodes.TRAVAILLEUR_HANDICAPE_ANNEE)
        ) {
            if (value < 1) {
                return messagesInscriptionSession.YEAR_MORE_THAN_ZERO;
            }
        }
    }

    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            info={info}
            a11yTitle={
                label + required ? " (Obligatoire pour soumission)" : undefined
            }
            validate={validateNumber}
            htmlFor={name}
            {...rest}
        >
            <MaskedInput
                mask={[
                    {
                        regexp: finalRegex,
                    },
                ]}
                id={name}
                name={name}
                placeholder={placeholder}
            />
        </FormField>
    );
};
