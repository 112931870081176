import { paths } from "../paths";

export const agentContact = {
    getAdministrations: (callback) => {
        fetch(`${paths.API_REF_ADMINISTRATION}?projection=forContactPage`)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            callback(data.content);
        })
    },
};