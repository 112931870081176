import { Box, Button } from "grommet";
import { Location } from "grommet-icons";

import { useEffect, useState } from "react";
import { LExtended as L } from "../../leaflet-plugin";
import "leaflet/dist/leaflet.css";
import "../../leaflet-plugin/leaflet.css";

export const Carte = (props) => {
    const [lienGMap, setLienGMap] = useState(undefined);
    const [map, setMap] = useState(undefined);
    const [displayMap, setDisplayMap] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        function fetchGeoportail(lat, lng) {
            try {
                const latLng = [
                    lat,
                    lng,
                ];
                var mapTemp = L.map("map" + props.mapIndex, {
                    center: latLng,
                    zoom: 16,
                    layers: plan,
                });

                var plan = L.tileLayer(
                    "https://wxs.ign.fr/{ignApiKey}/geoportail/wmts?" +
                        "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM" +
                        "&LAYER={ignLayer}&STYLE={style}&FORMAT={format}" +
                        "&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}",
                    {
                        opacity: 1,
                        ignApiKey: "decouverte",
                        ignLayer: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2",
                        style: "normal",
                        format: "image/png",
                        service: "WMTS",
                    }
                ).addTo(mapTemp);

                plan.on('load', () => setDisplayMap(true));
                plan.on('tileerror', () => setHasErrors(true));

                const defaultIcon = new L.icon({
                    iconUrl: "marker-icon-2x.png",
                    shadowUrl: "marker-shadow.png",
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41],
                });

                L.marker(latLng, { icon: defaultIcon })
                    .addTo(mapTemp)
                    .bindPopup(`<b>
                    ${props.epreuve.adresse}</b><br />`);
                setMap(mapTemp);
            } catch (error) {
                console.log(error);
            }
        }

        if (props.epreuve) {
            if (props.epreuve.latitudeSalle && props.epreuve.longitudeSalle) {
                setLienGMap(`https://google.com/maps/search/?api=1&query=${props.epreuve.latitudeSalle},${props.epreuve.longitudeSalle}`);
                fetchGeoportail(
                    props.epreuve.latitudeSalle,
                    props.epreuve.longitudeSalle
                );
            } else if (props.epreuve.adresse) {
                const search = props.epreuve.adresse.split(" ").join("+");
                const fullSearch = search + "+" + props.epreuve.codePostal;

                setLienGMap("https://google.com/maps/search/?api=1&query=" + fullSearch);

                fetch(
                    `https://api-adresse.data.gouv.fr/search/?q=${search}&autocomplete=0&limit=1&postcode=${props.epreuve.codePostal}`,
                    {
                        method: "GET",
                        mode: "cors",
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.features.length > 0) {
                            const coordinates =
                                data.features[0].geometry.coordinates;
                            const lat = coordinates[1];
                            const lng = coordinates[0];
                            fetchGeoportail(lat, lng);
                        } else {
                            console.log("Aucune donnée disponible pour l'adresse");
                        }
                    });
            }
        }
    }, [props.epreuve]);

    function handleMapButtonClick() {
        window.open(
            lienGMap,
            "_blank"
        );
    }
    
    return (
        <Box>
            <div
                id={"map" + props.mapIndex}
                style={{ height: displayMap && !hasErrors ? "220px" : "0px", width: "100%" }}
            >
                <Box
                    elevation="xlarge"
                    background="white"
                    round="18px"
                    style={{
                        bottom: "2%",
                        right: "2%",
                        zIndex: 9000,
                        position: "absolute",
                        display: displayMap && !hasErrors ? "inline-flex" : "none",
                    }}
                >
                    <Button
                        onClick={handleMapButtonClick}
                        size="small"
                        icon={<Location role="img" />}
                        label="Se rendre à la prochaine épreuve"
                    />
                </Box>
            </div>
        </Box>);
};
