export const paths = {
    DEV_BACKEND_URL: 'http://localhost:8081',
    HOME: `/`,
    SITE_MAP: `/plan`,
    CONTACT: `/contact`,
    LISTE_SESSIONS: `/recherche/sessions`,
    ESPACE_CANDIDAT: `/espacecandidat`,
    CREATION_COMPTE: `/creationcandidat`,
    INSCRIPTION_SESSION: `/inscription/:idSession`,
    INSCRIPTION_SESSION_FIN: `/inscription/:idSession/fin`,
    CONFIRMATION_RATTACHEMENT_EMAIL: `/rattachement/email/confirmation`,
    URL_MININT: `https://www.interieur.gouv.fr/`,
    ERROR_401: '/401',
    ERROR_404: '/404',

    FRANCE_CONNECT_AUTHORIZE_CALLBACK:`/login-callback`,
    FRANCE_CONNECT_AUTHORIZE_CALLBACK_ENCODED:`%2Flogin-callback`,
    FRANCE_CONNECT_LOGOUT_CALLBACK_ENCODED:`%2Flogout-callback`,
    FRANCE_CONNECT_LOGOUT_CALLBACK:`/logout-callback`,

    API_COMPTE_INFOS: '/compte/infos',
    API_COMPTE_CREATION: `/compte/create`,
    API_COMPTE_MDP_OUBLIE: `/compte/motdepasseoublie`,
    API_COMPTE_MDP_OUBLIE_POST: `/compte/password/reset`,
    API_COMPTE_UPDATE: '/compte/update',
    API_COMPTE_EMAIL_UPDATE: '/compte/email/update',
    API_COMPTE_LOGIN: '/compte/login',
    API_CORPS: `/refCorps`,
    API_TEXT_FINDBYCODE: `/refTexte/search/findByCode`,
    API_SESSION: `/sessions`,
    API_SESSION_CRITERIAS: `/sessions/criteres`,
    API_SESSION_SEARCH: `/sessions/recherche`,
    API_REF_BUR_RECRUT: `/refBurRecruts`,
    API_REF_ADMINISTRATION: `/refAdministrations`,
    API_LABELS_INSCRIPTION: `/labels/inscription/:idSession`,
    API_ONGLET_INTERNET_SESSION: `/inscription/:idSession/ongletsAutresRens`,
    API_ONGLET_COMPETENCES_SESSION: `/inscription/:idSession/competences`,
    API_INSCRIPTION_REFERENTIEL: `/inscription/referentiel`,
    API_INSCRIPTION_DROITS: `/inscription/referentiel/droits`,
    API_INSCRIPTION_SUPPRIMER: `/inscription/:idSession/supprimer`,
    API_INSCRIPTION_ENREGISTRER: `/inscription/:idSession/enregistrer`,
    API_INSCRIPTION_VALIDER: `/inscription/:idSession/valider`,
    API_INSCRIPTION_GET_DOSSIER: `/inscription/:idSession/saved`,
    API_INSCRIPTION_POST_VALIDATION: `/inscription/:idSession/recapitulatif`,
    API_AUTORISATION_INSCRIPTION: `/inscription/:idSession/ouverte`,
    API_TELECHARGER_MODELE_PJ: '/piecesjointes/:idPiece/modele',
    API_PIECE_JOINTE_UPLOAD: '/piecesjointes/upload',
    API_PIECE_JOINTE_LIST: '/piecesjointes/:idDossier/uploaded',
    API_PIECE_JOINTE_DELETE: '/piecesjointes/:idDossier/delete/:idPieceJointe',
    API_CANDIDAT_CANDIDATURES: `/candidat/candidatures`,
    API_CANDIDAT_CONVOCATION: '/candidat/convocation',
    API_CANDIDAT_RELEVE: '/candidat/releve',
    API_COMPTE_RATTACHER_LOGIN: `/compte/rattachement/login`,
    API_COMPTE_RATTACHER_EMAIL: `/compte/rattachement/email`,
    API_COMPTE_RATTACHER_EMAIL_CONFIRMATION: `/compte/rattachement/email/confirmation`,
    API_FRANCE_CONNECT_AUTHORIZE: `/franceConnect/authorizeUrl`,
    API_FRANCE_CONNECT_CONNEXION: `/franceConnect/connexion`,
    API_FRANCE_CONNECT_LOGOUT: '/franceConnect/logoutUrl',
    API_PIECE_ADMIN_EXEMPLE: '/pieceadmin/exemple',
    API_PIECE_ADMIN_DOCUMENT: '/pieceadmin/document',
    API_PIECE_ADMIN_UPLOAD: '/pieceadmin/upload',
    API_PIECE_ADMIN_CONFIG: '/pieceadmin/config'
};
