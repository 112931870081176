import {
    Box,
    Button,
    Form,
    Text,
    TextInput,
} from "grommet";
import { useState } from "react";
import { StatusGood } from "grommet-icons";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { messagesCommon } from "../messages/messagesCommon";
import { agentCandidat } from "./agentCandidat";

export const RattachementLogin = () => {
    const [result, setResult] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        setResult(undefined);
        setLoading(true);
        agentCandidat.rattacherCompteLogin(
            e.value["rattachementPseudo"],
            e.value["rattachementPassword"],
            (result) => {
                setResult(result.ok);
                setLoading(false);
            }
        );
    };

    return (
        <Box
            alignSelf="center"
            margin={{ top: "small" }}
            gap="medium"
            width="70%"
        >
            {result === undefined || result === false ? (
                <Box>
                    <Text>{messagesEspaceCandidat.EXPLIC_RATTACH_LOGIN}</Text>
                    <Form onSubmit={onSubmit}>
                        <TextInput
                            name="rattachementPseudo"
                            placeholder={messagesCommon.PSEUDO}
                        />
                        <Box margin={{ top: "xsmall" }}>
                            <TextInput
                                name="rattachementPassword"
                                type={"password"}
                                placeholder={messagesCommon.PASSWORD}
                            />
                        </Box>
                        {result === false && (
                            <Text weight="bold" color="red">
                                {messagesCommon.ERROR_RATTACHEMENT_LOGIN}
                            </Text>
                        )}
                        <Box>
                            <Button
                                type="submit"
                                label={messagesEspaceCandidat.RATTACHER}
                                primary
                                disabled={loading}
                                margin={{ top: "small" }}
                            />
                        </Box>
                    </Form>
                </Box>
            ) : (
                <Box gap="medium" align="center">
                    <StatusGood size="xlarge" color="limegreen" />
                    <Text>{messagesEspaceCandidat.CONFIRM_RATTACH_LOGIN}</Text>
                </Box>
            )}
        </Box>
    );
};
