export const config = {
    ALLOWED_FILE_TYPES: undefined,
    COOKIE_NAME: "SICMI_AUTH",
    CSRF_COOKIE: "XSRF-TOKEN",
    FC_COOKIE_NAME: "SICMI_FC_AUTH",
    DISCONNECT_STATE: "sicmi_disconnect_state",
    CONNECT_STATE: "sicmi_connect_state",
    SICMI_FORM_PREFIX: "sicmiform",
    DAYS_BEFORE_EPREUVE: 5,
    PIECE_ADMIN_MAXSIZE_BYTES: undefined
};