import {
    Anchor,
    Box,
    Grommet,
    Heading,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
} from "grommet";

import { messagesContact } from "../messages/messagesContact";
import { useScreenSize } from "../responsive";

export const AdministrationContact = (props) => {
    const contactsTheme = {
        anchor: {
            color: "#000091",
        },
        table: {
            body: {
                border: "full",
                pad: { horizontal: "small" },
            },
            header: {
                align: "center",
                border: "bottom",
                fill: "horizontal",
                pad: { horizontal: "small" },
                verticalAlign: "bottom",
                background: {
                    color: "#000091",
                },
            },
        },
    };

    const administration = props.administration;
    const columns = [
        { label: messagesContact.BUREAU, property: "bureau" },
        { label: messagesContact.COORDONNEES, property: "coordonnees" },
    ];

    const size = useScreenSize();

    const padding =
        size !== "small"
            ? {
                  right: "xlarge",
                  left: "xlarge",
                  top: "medium",
                  bottom: "medium",
              }
            : {
                  top: "medium",
                  bottom: "medium",
              };

    return (
        <Grommet theme={contactsTheme}>
            <Box fill pad={padding}>
                <Heading level={3}>{administration.libelle}</Heading>
                <Table>
                    <TableHeader>
                        <TableRow>
                            {columns.map((c) => (
                                <TableCell key={c.property} scope="col">
                                    <Text>{c.label}</Text>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {administration.trefBurRecruts.map((burRecrut) => (
                            <TableRow key={burRecrut.trefBurRecrutId}>
                                <TableCell key="1" scope="row">
                                    <Text>{burRecrut.libelle}</Text>
                                </TableCell>
                                <TableCell key="2" scope="row">
                                    <Box>
                                        <Text>{burRecrut.adresse1}</Text>
                                        <Text>{burRecrut.adresse2}</Text>
                                        <Text>{burRecrut.codePostal}</Text>
                                        <Text>{burRecrut.commune}</Text>
                                    </Box>
                                    {burRecrut.telephone ? (
                                        <Box>
                                            <Text>
                                                <abbr
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                    title={
                                                        messagesContact.TELEPHONE
                                                    }
                                                >
                                                    {messagesContact.TEL}
                                                </abbr>
                                                {` : ${burRecrut.telephone}`}
                                            </Text>
                                        </Box>
                                    ) : null}
                                    {burRecrut.fax ? (
                                        <Box>
                                            <Text>
                                                {messagesContact.FAX}
                                                {` : ${burRecrut.fax}`}
                                            </Text>
                                        </Box>
                                    ) : null}
                                    {burRecrut.email ? (
                                        <Box>
                                            <Text>
                                                <span
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                >
                                                    {messagesContact.EMAIL}
                                                </span>
                                                {" : "}
                                                <Anchor
                                                    href={`mailto:${burRecrut.email}`}
                                                    title={`${messagesContact.SEND_EMAIL}${burRecrut.email}`}
                                                >
                                                    {burRecrut.email}
                                                </Anchor>
                                            </Text>
                                        </Box>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Grommet>
    );
};
