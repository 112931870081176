import { config } from "../config";
import { getCookie } from "./cookieManager";
import { store } from "./store";

export const injectAuthHeader = (headers: any) => {
    if (store.connected === true) {
        headers.Authorization = `Bearer ${store.token}`;
    }
    return headers;
};

export const injectCsrfHeader = (headers: any) => {
    let cookie = getCookie(config.CSRF_COOKIE);
    if (cookie) {
        headers["X-XSRF-TOKEN"] = cookie;
    }
    return headers;
};
