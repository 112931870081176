// @ts-nocheck
// Obligatoire pour pouvoir ajouter les tags Eulerian sans faire crasher la compilation
import { Box, Button, Heading, Text } from "grommet";
import { useCallback, useEffect, useState } from "react";
import { paths } from "../paths";
import { messagesListeSessions } from "../messages/messagesListeSessions";
import { Recherche } from "../accueil/Recherche";
import { CritereSelect } from "./CritereSelect";
import { ResultatsRecherche } from "./ResultatsRecherche";
import { agentSessions } from "./agentSessions";
import { useResponsive } from "../responsive";

export const ListeSessionsContent = ({params}) => {
    const [activateSearchButton, setActivateSearchButton] = useState(true);

    const [filtres, setFiltres] = useState({
        administration: undefined,
        recrutement: undefined,
        gradeRecrutement: undefined,
        corps: undefined,
        competence: undefined,
        specialite: undefined,
        bureau: undefined,
        etatSession: "ouverte",
    });

    const [recherche, setRecherche] = useState(params.recherche);

    const [criteres, setCriteres] = useState({} as any);

    const etatSessionOptions = [
        { label: "Ouverte", value: "ouverte" },
        { label: "A venir", value: "attente" },
    ];

    const [noResult, setNoResult] = useState(null);

    const [resultats, setResultats] = useState(null);

    const updateFilters = (key, value) => {
        value = value === "" ? undefined : value;
        filtres[`${key}`] = value;

        // On passe par un nouvel item sinon react ne va pas re-render car il part du principe
        // que la référence de filtres n'a pas changé et donc qu'il ne s'est rien passé
        const updatedFiltres = { ...filtres };
        setFiltres(updatedFiltres);
    };

    const searchDone = (content) => {
        setActivateSearchButton(true);
        setNoResult(content && content.length ? false : true);

        if (content && content.length > 0) {
            //On formatte les dates pour l'affichage
            content = content.map((r) => {
                if (r.limiteDepotDossierInternet) {
                    r.limiteDepotDossierInternetFormatted = new Date(
                        r.limiteDepotDossierInternet
                    ).toLocaleDateString();
                }
                if (r.ouvertureInscriptionInternet) {
                    r.ouvertureInscriptionInternetFormatted = new Date(
                        r.ouvertureInscriptionInternet
                    ).toLocaleDateString();
                }
                if (r.datePremiereEpreuve) {
                    r.datePremiereEpreuveFormatted = new Date(
                        r.datePremiereEpreuve
                    ).toLocaleDateString();
                }
                return r;
            });
        }
        setResultats(content);
    };

    const doSearch = useCallback(() => {
        let filtresValides = {};
        setActivateSearchButton(false);
        for (const [key, value] of Object.entries(filtres)) {
            if (value !== undefined) {
                filtresValides[`${key}`] = value;
            }
        }

        if (recherche && recherche.trim()) {
            filtresValides["recherche"] = recherche.trim();
        }

        if (params.codeBureau && !filtresValides["bureau"]) {
            filtresValides["codeBureau"] = params.codeBureau;
        }

        agentSessions.doSearch(filtresValides, (content) =>
            searchDone(content)
        );
    }, [filtres, recherche]);

    useEffect(() => {
        if (
            (!criteres.listAdministrations ||
            criteres.listAdministrations.length <= 0)
        ) {
            
            agentSessions.getCriteres((content) => {
                setCriteres(content);

                const codeConfigurations = [
                    { code: 'codeCorps', liste: 'listCorps', champ: 'code', filtre: 'corps'},
                    { code: 'codeCateg', liste: 'listCategRecruts', champ: 'trefCategRecrutId', filtre: 'recrutement' },
                    { code: 'codeAdmin', liste: 'listAdministrations', champ: 'trefAdministrationId', filtre: 'administration'},
                    { code: 'codeGrade', liste: 'listGradeRecruts', champ: 'trefGradeRecrutId', filtre: 'gradeRecrutement'},
                    { code: 'codeComp', liste: 'listCompetences', champ: 'trefCompetenceId', filtre: 'competence'},
                    { code: 'codeSpec', liste: 'listSpecialites', champ: 'trefSpecialiteId', filtre: 'specialite' }
                ];

                let codeOk = false;
                let path = paths.LISTE_SESSIONS;

                for (const config of codeConfigurations) {
                    if (params[config.code] && content[config.liste]) {
                        let result = content[config.liste].find(
                            (c) => c.code === params[config.code]
                        );

                        if (result !== undefined) {
                            codeOk = true;
                            path += `/${config.code}/${params[config.code]}`;
                            filtres[config.filtre] = result[config.champ];
                        }
                    }
                }

                if (params.codeBureau) {
                    codeOk = true;
                }

                if (codeOk && typeof window["EA_push"] === "function") {
                    EA_push([
                      "path", params.codeBureau ? path += `/codeBureau/${params.codeBureau}` : path,
                      "pagelabel", "Recherche de concours",
                    ]);
                }

                if (codeOk || params.recherche) {
                    doSearch();
                }
            });
        }
    }, [params, criteres.listAdministrations, filtres, doSearch]);

    const smallTheme = {
        headingLevel: "1",
        margin: "xlarge",
    };

    const regularTheme = {
        gap: "xlarge",
        headingLevel: "3",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <>
            <Recherche
                value={recherche}
                onChange={setRecherche}
                onSubmit={doSearch}
            />
            <Box flex overflow="auto">
                {criteres && criteres.listAdministrations ? (
                    <>
                        <Box flex={false} fill="horizontal" align="center">
                            <Box>
                                <Box
                                    direction="row-responsive"
                                    gap={responsiveTheme.gap}
                                    pad="small"
                                >
                                    <CritereSelect
                                        label={
                                            messagesListeSessions.ADMINISTRATION_EMPLOI
                                        }
                                        name="administration"
                                        labelKey="libelle"
                                        valueKey="trefAdministrationId"
                                        options={criteres.listAdministrations}
                                        value={filtres.administration}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={
                                            messagesListeSessions.RECRUTEMENT
                                        }
                                        name="recrutement"
                                        labelKey="libelle"
                                        valueKey="trefCategRecrutId"
                                        options={criteres.listCategRecruts}
                                        value={filtres.recrutement}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={messagesListeSessions.GRADE}
                                        name="gradeRecrutement"
                                        labelKey="libelle"
                                        valueKey="trefGradeRecrutId"
                                        options={criteres.listGradeRecruts}
                                        value={filtres.gradeRecrutement}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={messagesListeSessions.CORPS}
                                        name="corps"
                                        labelKey="libelle"
                                        valueKey="code"
                                        options={criteres.listCorps}
                                        value={filtres.corps}
                                        updateFilters={updateFilters}
                                    />
                                </Box>
                                <Box
                                    direction="row-responsive"
                                    gap={responsiveTheme.gap}
                                    pad="small"
                                >
                                    <CritereSelect
                                        label={messagesListeSessions.COMPETENCE}
                                        name="competence"
                                        labelKey="libelle"
                                        valueKey="trefCompetenceId"
                                        options={criteres.listCompetences}
                                        value={filtres.competence}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={messagesListeSessions.SPECIALITE}
                                        name="specialite"
                                        labelKey="libelle"
                                        valueKey="trefSpecialiteId"
                                        options={criteres.listSpecialites}
                                        value={filtres.specialite}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={messagesListeSessions.BUREAU_RECRUTEMENT}
                                        name="bureau"
                                        labelKey="libelle"
                                        valueKey="trefBurRecrutId"
                                        options={criteres.listBurRecruts}
                                        value={filtres.bureau}
                                        updateFilters={updateFilters}
                                    />
                                    <CritereSelect
                                        label={
                                            messagesListeSessions.ETAT_SESSION
                                        }
                                        name="etatSession"
                                        labelKey="label"
                                        valueKey="value"
                                        options={etatSessionOptions}
                                        value={filtres.etatSession}
                                        clear={false}
                                        updateFilters={updateFilters}
                                    />
                                </Box>
                            </Box>
                            <Box direction="row" pad="small">
                                {activateSearchButton && (
                                    <Button
                                        type="button"
                                        label={
                                            messagesListeSessions.RECHERCHE_BTN
                                        }
                                        primary
                                        margin={{ top: "small" }}
                                        onClick={() => doSearch()}
                                    />
                                )}
                                {!activateSearchButton && (
                                    <Box
                                        align="center"
                                        justify="center"
                                        direction="row"
                                        gap="small"
                                    >
                                        <Box
                                            direction="row"
                                            border={[
                                                {
                                                    side: "all",
                                                    color: "transparent",
                                                    size: "medium",
                                                },
                                                {
                                                    side: "horizontal",
                                                    color: "brand",
                                                    size: "medium",
                                                },
                                            ]}
                                            pad="small"
                                            round="full"
                                            animation={{
                                                type: "rotateRight",
                                                duration: 1500,
                                            }}
                                        />
                                        <Text weight="bold">
                                            Recherche en cours
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {noResult === true && (
                            <Heading
                                level={responsiveTheme.headingLevel}
                                alignSelf="center"
                                margin={responsiveTheme.margin}
                            >
                                {messagesListeSessions.NO_RESULT}
                            </Heading>
                        )}
                        {resultats && resultats.length > 0 && (
                            <ResultatsRecherche resultats={resultats} />
                        )}
                        {resultats === undefined && (
                            <Box margin={{ bottom: "large" }} />
                        )}
                    </>
                ) : null}
            </Box>
        </>
    );
};
