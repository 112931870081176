import { paths } from "../paths";

export const agentSessions = {
    getCriteres: (callback) => {
        fetch(paths.API_SESSION_CRITERIAS)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            callback(data);
        })
    },
    doSearch: (filtres, callback) => {
        fetch(paths.API_SESSION_SEARCH + '?' + new URLSearchParams(filtres))
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.json();
        })
        .then((data) => {
            callback(data);
        })
        .catch((err) => {
            console.log(err);
            callback(undefined);
        });
    },
};