import { Box, Button, Layer, Markdown, Text } from "grommet";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../auth/store";
import { config } from "../config";
import { messagesCommon } from "../messages/messagesCommon";

import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { paths } from "../paths";
import { agentInscriptions } from "./agentInscriptions";

export const SessionsIncompatiblesModal = (props) => {
    const history = useHistory();

    const [incompat, setIncompat] = useState(false);
    const [conflit, setConflit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [deleteError, setDeleteError] = useState(false);

    const onClose = (idSession) => {
        if (idSession) {
            agentInscriptions.supprimerDossier(
                () => {
                    localStorage.removeItem(
                        config.SICMI_FORM_PREFIX +
                            props.idSession +
                            store.compte_id
                    );
                    history.push(paths.LISTE_SESSIONS);
                },
                () => {
                    setDeleteError(true);
                },
                idSession
            );
        } else {
            props.onClose();
        }
    };

    useEffect(() => {
        if (
            props.sessionsConflit === null &&
            props.sessionsIncompat?.length > 0
        ) {
            setIncompat(true);
        } else if (props.sessionsConflit?.length > 0) {
            setConflit(true);
            if (props.sessionsConflit[0].statut === "E") {
                setCanDelete(true);
            }
        }
    }, [props]);

    return (
        <Layer position="center">
            {deleteError && (
                <Box pad="medium" gap="small" width="large">
                    <Text color="red">{messagesCommon.FC_ERROR}</Text>
                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        align="center"
                        justify="center"
                        pad={{ top: "medium", bottom: "small" }}
                    >
                        <Button
                            label={messagesInscriptionSession.BACK_SESSIONS}
                            onClick={() => history.push(paths.LISTE_SESSIONS)}
                            primary
                        />
                    </Box>
                </Box>
            )}
            {!deleteError && (
                <Box pad="large" gap="small" width="large" overflow="auto">
                    <Text>
                        {incompat &&
                            messagesInscriptionSession.SESSION_INCOMPAT_1}
                        {conflit &&
                            messagesInscriptionSession.SESSION_CONFLICT_1}
                    </Text>
                    <Markdown>{"* " + props.libelleSession}</Markdown>
                    <Text>
                        {incompat &&
                            messagesInscriptionSession.SESSION_INCOMPAT_2}
                        {conflit &&
                            messagesInscriptionSession.SESSION_CONFLICT_2}
                    </Text>
                    {conflit && (
                        <Markdown>
                            {"* " + props.sessionsConflit[0].libelleComplet}
                        </Markdown>
                    )}

                    {incompat &&
                        props.sessionsIncompat.map((session) => (
                            <Markdown>{"* " + session.libelleComplet}</Markdown>
                        ))}
                    {conflit && !canDelete && (
                        <Text>
                            {
                                messagesInscriptionSession.SESSION_CONFLICT_CONTACT
                            }
                        </Text>
                    )}
                    {conflit && canDelete && (
                        <Text>
                            {messagesInscriptionSession.SESSION_CONFLICT_DELETE.replace(
                                "{0}",
                                props.sessionsConflit[0].libelleComplet
                            )}
                        </Text>
                    )}

                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        align="center"
                        justify={conflit && !canDelete ? "center" : "between"}
                        pad={{ top: "medium" }}
                    >
                        {(incompat || (conflit && canDelete)) && (
                            <>
                                <Button
                                    label={
                                        incompat
                                            ? messagesInscriptionSession.SESSION_INCOMPAT_STOP
                                            : messagesInscriptionSession.SESSION_CONFLICT_NO_DELETE_BTN
                                    }
                                    onClick={() =>
                                        history.push(paths.LISTE_SESSIONS)
                                    }
                                />
                                <Button
                                    label={
                                        incompat
                                            ? messagesInscriptionSession.SESSION_INCOMPAT_CONTINUE
                                            : messagesInscriptionSession.SESSION_CONFLICT_DELETE_BTN
                                    }
                                    onClick={() =>
                                        onClose(
                                            conflit
                                                ? props.sessionsConflit[0]
                                                      .sessionId
                                                : undefined
                                        )
                                    }
                                    primary
                                />
                            </>
                        )}
                        {conflit && !canDelete && (
                            <Button
                                label={messagesInscriptionSession.BACK_SESSIONS}
                                onClick={() =>
                                    history.push(paths.LISTE_SESSIONS)
                                }
                                primary
                            />
                        )}
                    </Box>
                </Box>
            )}
        </Layer>
    );
};
