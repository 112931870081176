import { Box, Text } from "grommet";

export const SpinnerWithText = (props) => {
    return (
        <Box align="center" justify="center" direction="row" gap="small">
            <Box
                direction="row"
                border={[
                    {
                        side: "all",
                        color: "transparent",
                        size: "medium",
                    },
                    {
                        side: "horizontal",
                        color: "brand",
                        size: "medium",
                    },
                ]}
                pad="small"
                round="full"
                animation={{
                    type: "rotateRight",
                    duration: 1500,
                }}
            />
            <Text weight="bold">{props.texte}</Text>
        </Box>
    );
};
