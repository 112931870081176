import React, { useReducer } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Index } from "./accueil/index";
import { EspaceCandidat } from "./candidat/EspaceCandidat";
import { CreationCompte } from "./candidat/CreationCompte";
import { Contact } from "./contact/Contact";
import { InscriptionSession } from "./inscription/InscriptionSession";
import { paths } from "./paths";
import { ListeSessions } from "./sessions/ListeSessions";
import { PlanSite } from "./sitemap/PlanSite";
import { store } from "./auth/store";
import { getCookie } from "./auth/cookieManager";
import { ErrorPage } from "./error/ErrorPage";
import { ConfirmationRattachementEmail } from "./candidat/ConfirmationRattachementEmail";
import { messagesCommon } from "./messages/messagesCommon";
import { LoginCallback } from "./franceconnect/LoginCallback";
import { config } from "./config";
import { FatalErrorModal } from "./common/FatalErrorModal";
import { Grommet } from "grommet";
import { theme } from "./theme";
import { FinalStep } from "./inscription/FinalStep";

export const ErrorDispatch = React.createContext(null);

export const App = () => {
    const token = getCookie(config.COOKIE_NAME);
    const csrf = getCookie(config.CSRF_COOKIE);
    if (token) {
        store.connected = true;
        store.token = token;
        store.fc_token_id = getCookie(config.FC_COOKIE_NAME);
    }
    if (csrf) {
        store.csrfToken = csrf;
    }

    const [fatalError, dispatchError] = useReducer((state, action) => {
        switch (action.type) {
            case "close":
                return "";
            default:
                //On ne veut pas changer le premier code d'erreur qui passe
                if (state) return state;
                return action.type;
        }
    }, "");



    if (window.location.pathname.includes("/pieceadmin")) {
        return <div />; //Cas spécial du téléchargement de pièce administrative
    } else {
        return (
            <ErrorDispatch.Provider value={dispatchError}>
                {fatalError && <FatalErrorModal type={fatalError} />}
                <Router>
                    <div>
                        <Switch>
                            {/* {store.connected && */}
                            <Route
                                exact
                                path={paths.INSCRIPTION_SESSION}
                                render={(props) => (
                                    <InscriptionSession
                                        params={props.match.params}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={paths.INSCRIPTION_SESSION_FIN}
                                render={(props) => (
                                    <FinalStep params={props.match.params} />
                                )}
                            />
                            <Route exact path={paths.ESPACE_CANDIDAT}>
                                <EspaceCandidat />
                            </Route>
                            <Route exact path={paths.CREATION_COMPTE}>
                                <CreationCompte />
                            </Route>
                            <Route exact path={paths.LISTE_SESSIONS}>
                                <ListeSessions />
                            </Route>
                            <Route exact path={paths.CONTACT}>
                                <Contact />
                            </Route>
                            <Route exact path={paths.SITE_MAP}>
                                <PlanSite />
                            </Route>
                            <Route
                                exact
                                path={paths.CONFIRMATION_RATTACHEMENT_EMAIL}
                            >
                                <ConfirmationRattachementEmail />
                            </Route>
                            <Route
                                exact
                                path={paths.FRANCE_CONNECT_AUTHORIZE_CALLBACK}
                            >
                                <LoginCallback />
                            </Route>
                            <Route
                                exact
                                path={paths.FRANCE_CONNECT_LOGOUT_CALLBACK}
                            >
                                <Grommet full theme={theme}>
                                    <Index />
                                </Grommet>
                            </Route>
                            <Route exact path={paths.HOME}>
                                <Grommet full theme={theme}>
                                    <Index />
                                </Grommet>
                            </Route>
                            <Route exact path={paths.ERROR_401}>
                                <ErrorPage
                                    text={messagesCommon.ERROR_401}
                                    returnPath={paths.HOME}
                                    returnLabel={messagesCommon.BACK_HOME}
                                />
                            </Route>
                            <Route>
                                <ErrorPage
                                    text={messagesCommon.ERROR_404}
                                    returnPath={paths.HOME}
                                    returnLabel={messagesCommon.BACK_HOME}
                                />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </ErrorDispatch.Provider>
        );
    }
};
