import { Box, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { VerticalDivider } from "../components/VerticalDivider";
import { Logo } from "../Logo";
import { LocaleDate } from "../components/LocaleDate";
import { getTheme } from "../etapeThemes";

export const CandidatureAcceptee = (props) => {
    const themeTypeForStatutDossier = {
        V: {
            type: "current",
            typeDone: "donePending",
            header: messagesEspaceCandidat.CANDIDATURE_ATTENTE,
            body: messagesEspaceCandidat.CANDIDATURE_ATTENTE_BODY,
        },
        T: {
            type: "ok",
            typeDone: "doneOK",
            header: messagesEspaceCandidat.CANDIDATURE_ACCEPTEE,
            body: messagesEspaceCandidat.CANDIDATURE_ACCEPTEE_BODY,
        },
        R: {
            type: "ko",
            typeDone: "doneKO",
            header: messagesEspaceCandidat.CANDIDATURE_REFUS,
            body: messagesEspaceCandidat.CANDIDATURE_REFUS_BODY,
        },
        E: {
            type: "pending",
            typeDone: "doneKO",
            header: messagesEspaceCandidat.CANDIDATURE_ATTENTE,
            headerDone: messagesEspaceCandidat.CANDIDATURE_CLOSES,
            body: messagesEspaceCandidat.CANDIDATURE_ATTENTE_VALIDATION_BODY,
            bodyDone: messagesEspaceCandidat.CANDIDATURE_CLOSES_BODY,
        },
    };

    const inscription = (() => {
        let type = themeTypeForStatutDossier[props.statutDossier];
        return props.cloture
            ? getTheme(type.typeDone, type.headerDone ? type.headerDone : type.header, type.bodyDone ? type.bodyDone : type.body)
            : getTheme(type.type, type.header, type.body);
    })();

    return (
        <Box direction="row" gap="small" fill="horizontal">
            <Box direction="column" width={{ min: "xxsmall", max: "xxsmall" }}>
                <Logo
                    background={inscription.theme.backgroundColor}
                    border={inscription.theme.border}
                >
                    {inscription.theme.logo}
                </Logo>
                <VerticalDivider
                    background={{
                        color: inscription.theme.dividerColor,
                        doted: inscription.theme.doted,
                    }}
                />
            </Box>
            <Box
                direction="column"
                gap="small"
                fill="horizontal"
                pad={{ bottom: "small" }}
            >
                <Box>
                    <LocaleDate
                        date={props.dateValidation}
                        color="grey"
                        locale="fr-FR"
                    />
                    <Text weight="bold" size="large" color="sicmiblack">
                        {inscription.header}
                    </Text>
                </Box>

                <Box border={{ size: "medium" }} round="small">
                    <Box pad="xsmall" round="small" background="sicmiwhite">
                        <Text margin="xsmall">{inscription.body}</Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
