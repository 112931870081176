import { Box, CheckBox, FormField, Heading } from "grommet";

import { ListFormField } from "./inputs/ListFormField";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { ToggleFormField } from "./inputs/ToggleFormField";
import { FormFieldLabel } from "./inputs/FormFieldLabel";
import { useEffect, useState } from "react";

export const ChoixEpreuves = (props) => {
    const allowDisplay = props.eprObl || props.eprFal;

    const EPR_FAL = "eprFal";
    const EPR_OBL = "eprObl";

    const [options, setOptions] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const tmpOptions = {};
        const tmpFal = props.eprFal ? props.eprFal : [];
        const totalEpreuves = props.eprObl
            ? props.eprObl.concat(tmpFal)
            : props.eprFal;

        if (totalEpreuves) {
            for (const epr of totalEpreuves) {
                if (epr.options?.length > 0) {
                    for (const option of epr.options) {
                        tmpOptions[option.toptionEpreuveId] = option;
                    }
                }
            }
            setOptions(tmpOptions);
        }
    }, [props.eprObl, props.eprFal]);

    useEffect(() => {
        setSelectedOptions(
            Object.entries(props.formulaire)
                .filter((obj) => obj[0].match("epr(Obl|Fal).*") && obj[1])
                .map((obj) => obj[1]["toptionEpreuveId"])
        );
    }, [props.formulaire, options]);

    const getLibelleForEpreuve = (epr) => {
        if (epr.libelleGroupe) {
            return epr.libelleGroupe + " - " + epr.libelleEpreuve;
        }

        return epr.libelleEpreuve;
    };

    const genererOptions = (epr, i, isObligatoire) => {
        if (!epr.options) {
            return (
                <ToggleFormField
                    name={(isObligatoire ? EPR_OBL : EPR_FAL) + epr.tepreuveId}
                    key={i}
                    label={getLibelleForEpreuve(epr)}
                    required={isObligatoire}
                />
            );
        }

        const optionsExcluded = epr.options.filter((opt) => {
            if (opt.exclusions?.length) {
                for (const exclusion of opt.exclusions) {
                    if (selectedOptions.includes(exclusion)) {
                        return true;
                    }
                }
            }
            return false;
        });

        return (
            <ListFormField
                name={(isObligatoire ? EPR_OBL : EPR_FAL) + epr.tepreuveId}
                key={i}
                label={getLibelleForEpreuve(epr)}
                required={isObligatoire}
                options={epr.options}
                disabled={optionsExcluded}
                labelKey="libelleOption"
                valueKey="toptionEpreuveId"
            />
        );
    };

    return (
        allowDisplay && (
            <Box>
                <Heading
                    level={2}
                    margin={{ bottom: "medium", top: "none" }}
                    textAlign="center"
                    fill
                >
                    {messagesInscriptionSession.CHOIX_EPR}
                </Heading>
                <Box gap="large">
                    {props.eprObl && props.eprObl.length > 0 && (
                        <Box>
                            <Heading
                                level={3}
                                margin={{ bottom: "medium", top: "none" }}
                            >
                                {messagesInscriptionSession.EPREUVES_OBL}
                            </Heading>

                            {props.eprObl.map((epr, i) => {
                                return (
                                    <>
                                        <Heading level={4}>
                                            {epr.libelleEtape}
                                        </Heading>
                                        {genererOptions(epr, i , true)}
                                    </>
                                );
                            })}
                        </Box>
                    )}

                    {props.eprFal && props.eprFal.length > 0 && (
                        <Box>
                            <Heading
                                level={3}
                                margin={{ bottom: "medium", top: "none" }}
                            >
                                {messagesInscriptionSession.EPREUVES_FAL}
                            </Heading>
                            {props.eprFal.map((epr, i) => {
                                return (
                                    <>
                                        <Heading level={4}>
                                            {epr.libelleEtape}
                                        </Heading>
                                        {genererOptions(epr, i , false)}
                                    </>
                                );
                            })}
                            <Box
                                pad={{
                                    top: "large",
                                    left: "small",
                                    right: "small",
                                }}
                            >
                                <FormField name={"okEprFal"} required>
                                    <CheckBox
                                        reverse
                                        a11yTitle={
                                            messagesInscriptionSession.ACKNOWLEDGE_OPTIONAL_TESTS
                                        }
                                        name={"okEprFal"}
                                        label={
                                            <FormFieldLabel
                                                label={
                                                    messagesInscriptionSession.ACKNOWLEDGE_OPTIONAL_TESTS
                                                }
                                                bold
                                                underline
                                                required={true}
                                            />
                                        }
                                    />
                                </FormField>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    );
};
