import { useEffect, useState } from "react";
import { FormClose, StatusCritical, StatusGood } from "grommet-icons";

import { Box, Button, Layer, Text } from "grommet";

export const Toast = (props) => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (props.count > 0 && props.count > count) {
            setOpen(true);
            setCount(props.count);
            if (props.timeout) {
                setTimeout(() => {
                    setOpen(false);
                }, props.timeout);
            }
        }
    }, [props.count]);

    const onClose = () => setOpen(false);
    const position = (props.position) ? props.position : "bottom";
    return (
        <>
            {open && (
                <Layer
                    position={position}
                    modal={false}
                    margin={{ vertical: "medium", horizontal: "small" }}
                    onEsc={onClose}
                    responsive={false}
                    plain
                >
                    <Box
                        align="center"
                        direction="row"
                        gap="small"
                        justify="between"
                        round="medium"
                        elevation="medium"
                        pad={{ vertical: "small", horizontal: "small" }}
                        background={props.status}
                    >
                        <Box align="center" direction="row" gap="xsmall">
                            {props.status === "status-ok" && <StatusGood />}
                            {props.status === "status-error" && (
                                <StatusCritical />
                            )}
                            <Text>{props.text}</Text>
                        </Box>
                        <Button icon={<FormClose />} onClick={onClose} plain />
                    </Box>
                </Layer>
            )}
        </>
    );
};
