import { CheckBox, FormField } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const ToggleFormField = (props) => {
    const { label, required, name, italicLabel, toggle, ...rest } = props;
    return (
            <CheckBox
                name={name}
                id={name}
                toggle={toggle}
                reverse
                required={required}
                label={
                    <FormFieldLabel
                        label={label}
                        required={required}
                        italicLabel={italicLabel}
                    />
                }
            />
    );
};
