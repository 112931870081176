export const theme = {
    global: {
        colors: {
            brand: "#000091",
            sicmigrey: '#A5A5B1',
            sicmiblack: '#4B4B57',
            sicmiwhite: '#FAFAFC',
            sicmigreen: '#1BB473',
            sicmired: '#e1000f',
            sicmiorange: '#EE612C',
            sicmiblue: '#000091',
            sicminotifbloc: '#28404C',
            sicminotiffade: '#6D8087',
        },
        font: {
            family: "Calibri, Arial, sans-serif",
        },
        focus: {
          border: {
            color: "brand"
          }
        }
    },

    button: {
      border: {
        radius: "6px",
      }
    },
    formField: {
      error: {
        color: "sicmired",
      },
      // Les gros bolosses de Grommet ont mis cette propriété en non null avec la 2.25.2, à voir s'ils corrigent à l'avenir
      checkBox: {
        pad: "none"
      }
    },
    dataTable: {
      header: {
        color: 'white',
      },
    },
    footer: {
      position: 'absolute',
      bottom: 0,
    },
    tip: {
      content: {
        background: { color: '#000091', opacity: 1 }
      }
    },
    //Décommenter pour inverser les couleurs des tabs (noir si non actif et brand si actif)
    /*tab: {
      active: {
        color: "brand",
      },
      color: "black",
      border: {
        side: "bottom",
        size: "small",
        color: "black",
        active: {
          color: "brand",
        },
        hover: {
          color: "brand",
        },
      },
      hover: {
        color: "brand",
      },
    }*/
};