import { Grommet, Main } from "grommet";

import { messagesCommon } from "../messages/messagesCommon";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { PlanSiteContent } from "./PlanSiteContent";

export const PlanSite = () => {
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: messagesCommon.SITE_MAP },
    ];

    return (
        <Grommet full theme={theme}>
                <HeaderSicmi
                    title={messagesCommon.SITE_MAP}
                    breadcrumbs={breadcrumbs}
                />
                <Main>
                    <PlanSiteContent />
                </Main>
                <FooterSicmi />
        </Grommet>
    );
};
