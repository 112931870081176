class ATInternetService {

    constructor() {
      this.tag = this.newTag({secure: true});
    }
    newTag(options) {
      try {
        return new window.ATInternet.Tracker.Tag(options);
      } catch(ex) {
        return {
          dispatch: () => ({}),
          page: { set: () => ({}) },
          click: { send: () => ({}) }
        };
      }
    }
    //@param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
    sendPage(info) {
      this.tag.page.set(info);
      this.tag.dispatch();
    }
    //@param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
    sendClick(info) {
      this.tag.click.send(info);
    }
  }
  export let tag = new ATInternetService();