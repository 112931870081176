import { Box, Heading } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { useResponsive } from "../../responsive";
import { PieceAdministrative } from "./PieceAdministrative";

export const BlocPiecesAdministratives = (props: any) => {

    const smallTheme = {
        border: undefined
    };

    const regularTheme = {
        border: { size: "small" }
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    return (
        <>
            {props.piecesAdmin && props.piecesAdmin.length > 0 && (
                <Box
                    pad="medium"
                    border={responsiveTheme.border}
                    round="medium"
                    gap="medium"
                >
                    <Heading
                        textAlign="start"
                        color="sicmiblack"
                        level="3"
                        margin="xxsmall"
                    >
                        {messagesEspaceCandidat.DEMANDE_PIECE_ADMIN_HEADER}
                    </Heading>
                    <Box direction="column" fill="horizontal">
                        <Box direction="column" gap="large">
                            {props.piecesAdmin.map(
                                (piece: any, index: number) => (
                                    <PieceAdministrative
                                        idSession={props.idSession}
                                        key={index}
                                        piece={piece}
                                    />
                                )
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};
