import { Box, Grommet, Heading, Image, Spinner, Text } from "grommet";
import { theme } from "../theme";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { agentLogin } from "../auth/agentLogin";
import { store } from "../auth/store";
import { getCookie, setCookie } from "../auth/cookieManager";
import { paths } from "../paths";
import { agentFranceConnect } from "./agentFranceConnect";
import { useLocation } from "react-router-dom";
import { EmailForm } from "./EmailForm";
import { RoutedAnchor } from "../anchor/RoutedAnchor";
import { messagesCommon } from "../messages/messagesCommon";
import { config } from "../config";

export const LoginCallback = () => {
    const [needEmail, setNeedEmail] = useState(false);

    const [loading, setLoading] = useState(true);

    const [hasError, setHasError] = useState(false);

    const [codeFC, setCodeFC] = useState(undefined);
    const [stateFC, setStateFC] = useState(undefined);
    const [email, setEmail] = useState(undefined);

    const urlParams = new URLSearchParams(useLocation().search);

    const history = useHistory();

    useEffect(() => {
        if (
            !store.connected &&
            urlParams.get("code") &&
            urlParams.get("state")
        ) {
            setCodeFC(urlParams.get("code"));
            setStateFC(urlParams.get("state"));
        } else {
            history.push("/");
        }
    }, [urlParams]);

    useEffect(() => {
        if (codeFC && stateFC) {
            const values = {
                urlCallback:
                    window.location.origin +
                    paths.FRANCE_CONNECT_AUTHORIZE_CALLBACK,
                cleUtilisateur: localStorage.getItem(config.CONNECT_STATE),
                code: codeFC,
                state: stateFC,
                email: email,
            };
            agentFranceConnect.connexion(
                values,
                email !== undefined,
                (data) => {
                    agentLogin.getInfosCompte(
                        data.kcToken.access_token,
                        (infos) => {
                            if (!infos) {
                                setLoading(false);
                                setNeedEmail(false);
                                setHasError(true);
                            } else {
                                localStorage.removeItem(config.CONNECT_STATE);
                                store.bearer = data.kcToken;
                                store.connected = true;
                                store.nom = infos.nom;
                                store.prenom = infos.prenom;
                                store.email = infos.email;
                                store.compte_id = infos.id;
                                store.token = data.kcToken.access_token;
                                store.fc_token_id = data.fcToken.id_token;

                                setCookie(
                                    config.COOKIE_NAME,
                                    data.kcToken.access_token,
                                    data.kcToken.expires_in
                                );

                                /*
                                 * On multiplie expires_in par 60 car FranceCOnnect renvoie des minutes pour ce champ
                                 * et alors que keycloak renvoie des secondes
                                 */
                                setCookie(
                                    config.FC_COOKIE_NAME,
                                    data.fcToken.id_token,
                                    data.fcToken.expires_in*60 
                                );

                                //On force le rechargement de toute la page pour actualiser les comportements liés au fait d'être connecté ou pas
                                history.push("/");
                            }
                        }
                    );
                },
                (emailNeeded) => {
                    setLoading(false);
                    setNeedEmail(emailNeeded);
                    setHasError(!emailNeeded);
                }
            );
        }
    }, [codeFC, stateFC, email]);

    const emailSubmitted = (email) => {
        if (email) {
            setLoading(true);
            setEmail(email);
        }
    };

    return (
        <Grommet full theme={theme}>
            <Box fill align="center" justify="center" gap="xlarge">
                <Box>
                    <Image src="/minint.png" />
                </Box>

                {loading && (
                    <Box fill align="center">
                        <Box
                            align="center"
                            justify="center"
                            direction="row"
                            gap="medium"
                        >
                            <Spinner size="large" color="sicmiblue" />
                            <Text weight="bold" size="large">
                                Veuillez patienter pendant que nous vous
                                authentifions
                            </Text>
                        </Box>
                    </Box>
                )}
                {needEmail && <EmailForm onEmailSubmit={emailSubmitted} />}
                {hasError && (
                    <Box fill align="center" gap="small">
                        <Heading>{messagesCommon.FC_ERROR}</Heading>
                        <Text>{messagesCommon.FC_ERROR_BODY}</Text>
                        <RoutedAnchor to="/" label={messagesCommon.BACK_HOME} />
                    </Box>
                )}
            </Box>
        </Grommet>
    );
};
