import { Text } from "grommet";

export const FormFieldLabel = (props) => {
    const { label, italicLabel, required, requiredForSave, bold, underline} = props;

    const formatLabel = (lbl) => {

        const style = {textDecoration: underline ? "underline" : "none", fontWeight: bold ? "bold" : "normal"};
        const split = lbl.split("<br>");
        if (split.length > 1) {
            let newLabel = lbl.replace("<br>", "\n");
            return <span style={{whiteSpace: "pre-line"}}>{newLabel}</span>
        } else {
            return <span style={style}>{lbl}</span>;
        }
    }

    return (
        <div>
            {formatLabel(label)}
            {italicLabel ? (<span style={{fontStyle: "italic"}}>{italicLabel}</span>) : undefined}
            {required && required === true ? (<Text weight="bold" size="large" color="status-critical">*{requiredForSave && "*"}</Text>) : undefined}
        </div>
    );
};
