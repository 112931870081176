import { Box, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { VerticalDivider } from "../components/VerticalDivider";
import { Logo } from "../Logo";
import { getTheme } from "../etapeThemes";
import { Carte } from "./Carte";
import { Epreuves } from "./Epreuves";
import { getEpreuvesDatesRangeAsLabel, getNextEpreuve, isLastEpreuveDone } from "../epreuvesHelper";

export const BlocEpreuves = (props) => {

    const themeEpreuve = (() => {
        let header =
            props.etape.epreuves?.length > 0
                ? props.etape.epreuves?.length + messagesEspaceCandidat.EPREUVE
                : messagesEspaceCandidat.EPREUVES;
        if (props.etape?.epreuves?.length > 1) {
            header += "s";
        }

        switch (props.etape.etat) {
            case "encours":
                //TODO calculer si les épreuves sont en cours ou finies
                if (!isLastEpreuveDone(props.etape.epreuves)) {
                    return getTheme("current", header, undefined);
                }
                return getTheme("ok", header, undefined);
            case "close":
                return getTheme("doneOK", header, undefined);
            default:
                return getTheme("pending", header, undefined);
        }
    })();

    //const nextEpreuve = getNextEpreuve(props.etape.epreuves);
    return (
        <Box direction="row" gap="small" fill="horizontal">
            <Box direction="column" width={{ min: "xxsmall", max: "xxsmall" }}>
                <Logo
                    background={themeEpreuve.theme.backgroundColor}
                    border={themeEpreuve.theme.border}
                >
                    {themeEpreuve.theme.logo}
                </Logo>
                <VerticalDivider
                    background={{
                        color: themeEpreuve.theme.dividerColor,
                        doted: themeEpreuve.theme.doted,
                    }}
                />
            </Box>
            <Box direction="column" gap="small" fill="horizontal">
                <Box height={{ min: "48px" }} justify="center">
                    {getEpreuvesDatesRangeAsLabel(props.etape.epreuves)}
                    <Text weight="bold" size="large" color="sicmiblack">
                        {themeEpreuve.header}
                    </Text>
                </Box>

                <Box
                    border={{ size: "medium" }}
                    round="small"
                    margin={{ bottom: "small" }}
                >
                    {props.etape.epreuves?.length > 0 && (
                        <Box pad="xsmall" round="small" background="sicmiwhite">
                            {props.etape.etat === "encours" && props.nextEpreuve && (
                                <Carte
                                    mapIndex={props.mapIndex}
                                    epreuve={props.nextEpreuve}
                                />
                            )}

                            <Epreuves epreuves={props.etape.epreuves} affichageNotes={props.etape.affichageNotes} />
                        </Box>
                    )}
                    {(!props.etape.epreuves ||
                        props.etape.epreuves.length <= 0) && (
                            <Box
                                pad="small"
                                round="small"
                                background="sicmiwhite"
                            >
                                <Text>{messagesEspaceCandidat.EPREUVES_NO_INFO}</Text>
                            </Box>
                        )}
                </Box>
            </Box>
        </Box>
    );
};
