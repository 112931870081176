import { Box, Button, FileInput, FormField, Heading, Text } from "grommet";
import { paths } from "../paths";
import { useCallback, useEffect, useState } from "react";
import { CaretRightFill } from "grommet-icons";
import { messagesCommon } from "../messages/messagesCommon";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { agentInscriptions } from "./agentInscriptions";
import { downloadUrl } from "../candidat/downloadUrl";

export const PiecesAJoindre = (props) => {

    const { idDossier, uploadedFiles, setUploadedFiles } = props;
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [file, setFile] = useState(undefined);

    const onFileDelete = (idPiece: number, index: number) => {
        setLoading(true);
        agentInscriptions.deletePiece(idDossier, idPiece, (result) => {
            if (result === true) {
                let temp = [...uploadedFiles];
                temp.splice(index, 1);
                setUploadedFiles(temp);
            }
            setLoading(false);
        });
    };

    const loadUploadedFiles = useCallback(() => {
        if (idDossier >= 0) {
            setLoading(true);
            agentInscriptions.getUploadedPiecesJointes(
                idDossier,
                (pieces) => {
                    if (pieces && Array.isArray(pieces) && pieces.length > 0) {
                        setUploadedFiles(pieces);
                    }
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            );
        }
    }, [idDossier, setUploadedFiles]);

    useEffect(() => {
        loadUploadedFiles();
        if (!props.isValid) {
            setErrorMsg("Le nombre de pièces jointes ne correspond pas : les pièces jointes doivent correspondre aux pièces demandées");
        }
    }, [loadUploadedFiles, props.isValid]);

    function onFileUpload() {
        const myFile: File = file;
        if (myFile && myFile.size > 0 && myFile.size < props.maxSize) {
            setLoading(true);
            setErrorMsg(undefined);
            let formData = new FormData();
            formData.append("file", myFile);
            formData.append("idSession", props.idSession);
            formData.append("idDossier", props.idDossier);
            agentInscriptions.uploadPiece(formData, (data) => {
                setLoading(false);
                if (data === true) {
                    loadUploadedFiles();
                } else if (data === "401") {
                    setErrorMsg(messagesCommon.ERROR_401);
                } else if (data === "412") {
                    setErrorMsg(messagesInscriptionSession.PJ_FILE_REJECTED);
                } else {
                    setErrorMsg(messagesCommon.BACKEND_ERROR);
                }
            });
            setFile(undefined);
        } else if (myFile && myFile.size > props.maxSize) {
            setErrorMsg(getFileSizeError());
        }
    }

    const getPieceAJoindreInput = (pieceAJoindre, i) => {

        const lien = downloadUrl(paths.API_TELECHARGER_MODELE_PJ.replace(
            ":idPiece",
            pieceAJoindre.tsessionPieceJointeId
        ), {});

        return (
            <FormField
                key={i}
                name={"pieceAJoindre" + pieceAJoindre.tsessionPieceJointeId}
            >
                <Box key={i} direction="row" justify="between">
                    <Box direction="row" align="center" gap="xsmall">
                        <CaretRightFill color="black" size="medium" />
                        <Box direction="row">
                            <Text>
                                {pieceAJoindre.designation}
                                {pieceAJoindre.facultative
                                    ? messagesInscriptionSession.OPTIONAL_DOCUMENT
                                    : undefined}
                            </Text>
                            {!pieceAJoindre.facultative && (
                                <Text color="status-critical">*</Text>
                            )}
                        </Box>
                    </Box>
                    {pieceAJoindre.cheminModele && (
                        <a
                            href={lien}
                            target="_blank"
                            rel="noreferrer"
                            download
                        >
                            {messagesInscriptionSession.DOWNLOAD_EXAMPLE}
                        </a>
                    )}
                </Box>
            </FormField>
        );
    };

    const getPieceJointe = (pieceJointe, i) => {
        return (
            <FormField key={i} name={"pieceJointe" + i}>
                <Box key={i} direction="row" justify="between">
                    <Box direction="row" align="center" gap="xsmall">
                        <CaretRightFill color="black" size="medium" />
                        <Text>{pieceJointe.nomFichier}</Text>
                    </Box>
                    <Button
                        disabled={loading}
                        color="status-critical"
                        plain
                        label="Supprimer"
                        onClick={() =>
                            onFileDelete(pieceJointe.tcandidatPieceJointeId, i)
                        }
                    />
                </Box>
            </FormField>
        );
    };

    function getFileSizeError() {
        return messagesInscriptionSession.PJ_MAXSIZE_ERROR.replace(
            "$size",
            String(props.maxSize / 1000000)
        );
    }

    return props.piecesAJoindre && props.piecesAJoindre.length > 0 ? (
        <Box border pad="medium" gap="large" direction="column">
            <Box gap="small">
                <Text weight="bold">
                    {messagesInscriptionSession.PIECES_A_JOINDRE}
                </Text>
                <Text style={{fontStyle: "italic"}}>
                    {props.messagePj}
                </Text>
                {props.piecesAJoindre.map((piece, i) =>
                    getPieceAJoindreInput(piece, i)
                )}
            </Box>

            {props.idDossier >= 0 &&
                (!props.uploadedFiles ||
                    props.uploadedFiles?.length < props.piecesAJoindre.length) && (
                    <Box justify="center" direction="row" gap="small">
                        <FileInput
                            multiple={false}
                            messages={messagesCommon.FILE_INPUT_MSG}
                            accept={props.allowedExtensions}
                            maxSize={props.maxSize}
                            onChange={(event) => {
                                if (
                                    (event.target as HTMLInputElement).files &&
                                    (event.target as HTMLInputElement).files
                                        .length
                                ) {
                                    const file: File = event.target.files[0];
                                    if (file.size > props.maxSize) {
                                        setErrorMsg(getFileSizeError());
                                    } else {
                                        setErrorMsg(undefined);
                                    }
                                    setFile(file);
                                } else {
                                    setFile(undefined);
                                    setErrorMsg(undefined);
                                }
                            }}
                        />
                        <Button
                            color="sicmiblack"
                            label="Ajouter"
                            disabled={loading || file === undefined}
                            onClick={() => onFileUpload()}
                        />
                    </Box>
                )}
            {props.idDossier < 0 && (
                <Box align="center">
                    <Heading
                        fill
                        responsive
                        color="status-critical"
                        textAlign="center"
                        level="3"
                    >
                        {messagesInscriptionSession.NEED_TO_SAVE_DOSSIER}
                    </Heading>
                </Box>
            )}
            {errorMsg && (
                <Box pad={{ left: "medium" }}>
                    <Text color="status-critical" weight="bold">
                        {errorMsg}
                    </Text>
                </Box>
            )}

            {props.uploadedFiles?.length > 0 && (
                <Box gap="small">
                    <Text weight="bold">
                        {messagesInscriptionSession.PIECES_JOINTES}
                    </Text>
                    {props.uploadedFiles.map((piece, i) => getPieceJointe(piece, i))}
                </Box>
            )}
            {(!props.uploadedFiles || props.uploadedFiles.length === 0) && (
                <Box gap="small">
                    <Text textAlign="center" weight="bold" size="large">
                        {messagesInscriptionSession.NO_DOCUMENT_UPLOADED}
                    </Text>
                </Box>
            )}
        </Box>
    ) : null;
};
