import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { LocaleDate } from "./components/LocaleDate";

export const isLastEpreuveDone = (epreuves) => {
    if (epreuves?.length > 0) {
        let lastDate = epreuves[epreuves.length - 1].finEpreuve;
       if (lastDate) {
         return new Date(lastDate) < new Date();
       }
    }

    return true;
};

export const getEpreuvesDatesRangeAsLabel = (epreuves) => {
    if (epreuves?.length > 0) {
        let firstDate = epreuves[0].debutEpreuve;
        let lastDate =
            epreuves.length > 1
                ? epreuves[epreuves.length - 1].debutEpreuve
                : undefined;
        return (firstDate ?
            <LocaleDate
                date={firstDate}
                lastDate={lastDate}
                color="grey"
                locale="fr-FR"
                textPrefix={
                    epreuves.length > 1
                        ? messagesEspaceCandidat.FROM
                        : undefined
                }
            />
        : undefined);
    }
};

export const getNextEpreuve = (epreuves) => {
    if (epreuves?.length > 0) {

        for(let epreuve of epreuves) {
            let date = epreuve.debutEpreuve;
            if (date && new Date(date) > new Date()) {
                return epreuve;
            }
        }

        return undefined;
        
    }
};