import { useState, useEffect } from "react";
import { Box, Image, Markdown, Text } from "grommet";

import { agent } from "./agent";

export const BlocsTextesAccueil = () => {
    const [texteGauche, setTexteGauche] = useState({} as any);
    const [texteMilieu, setTexteMilieu] = useState({} as any);
    const [texteDroite, setTexteDroite] = useState({} as any);

    useEffect(() => {
        agent.getRefTexteByCode(
            (content) => {return content ? setTexteGauche(content) : setTexteGauche("");},
            "bloc_accueil_1");
        agent.getRefTexteByCode(
            (content) => {return content ? setTexteMilieu(content) : setTexteMilieu("");},
            "bloc_accueil_2");
        agent.getRefTexteByCode(
            (content) => {return content ? setTexteDroite(content) : setTexteDroite("");},
            "bloc_accueil_3"
        );
    }, []);

    return (
        <Box direction="row-responsive" width={{ max: "900px" }}>
            <Box align="center" pad={{ right: "medium", left: "medium" }} gap="medium">
                <Box width="xsmall">
                    <Image role="img" src="logo1.png" />
                </Box>
                {texteGauche && texteGauche.libelle ? (
                    <Markdown>{texteGauche.libelle}</Markdown>
                ) : undefined}
            </Box>
            <Box align="center" pad={{ right: "xlarge", left: "xlarge" }} gap="medium">
                <Box width="xsmall">
                    <Image role="img" src="logo2.png" />
                </Box>
                {texteMilieu && texteMilieu.libelle ? (
                    <Markdown>{texteMilieu.libelle}</Markdown>
                ) : undefined}
            </Box>
            <Box align="center" pad={{ right: "medium", left: "medium" }} gap="medium">
                <Box width="xsmall">
                    <Image role="img" src="logo3.png" />
                </Box>
                {texteDroite && texteDroite.libelle ? (
                    <Markdown>{texteDroite.libelle}</Markdown>
                ) : undefined}
            </Box>
        </Box>
    );
};
