import { FormField, MaskedInput } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const EmailFormField = (props) => {
    const {
        label,
        italicLabel,
        required,
        name,
        length,
        onChange,
        value,
        ...rest
    } = props;

    const emailMask = [
        {
            regexp: /^[\w\-_.]+$/,
            placeholder: "exemple",
        },
        { fixed: "@" },
        {
            regexp: /^[\w-]+$/,
            placeholder: "hote",
        },
        { fixed: "." },
        {
            regexp: /^[\w.]+$/,
            placeholder: "com",
        },
    ];

    const finalValidate = required
        ? {
              regexp: new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"),
              message: "Email invalide",
          }
        : {
              regexp: new RegExp("^(?:[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4})?$"),
              message: "Email invalide",
          };
    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            htmlFor={name}
            validate={finalValidate}
            {...rest}
        >
            {onChange && (
                <MaskedInput
                    mask={emailMask}
                    name={name}
                    id={name}
                    value={value}
                    onChange={(event) => {
                        onChange(event.target.value);
                    }}
                    a11yTitle={`${label} ${
                        required ? " (Obligatoire pour soumission)" : ""
                    }`}
                />
            )}
            {!onChange && <MaskedInput a11yTitle={`${label} ${
                required ? " (Obligatoire pour soumission)" : ""
            }`} mask={emailMask} name={name} id={name} />}
        </FormField>
    );
};
