import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";

export const listeEtapes = {
    "ADMISSIBILITE": {
        nom: messagesEspaceCandidat.ADMISSIBILITE,
        desc: messagesEspaceCandidat.PHASE_ADMISSIBILITE_DESC,
        resultats_header: messagesEspaceCandidat.RESULTAT_HEADER_ADMISSIBILITE
    },
    "ADMISSION": {
        nom: messagesEspaceCandidat.ADMISSION,
        desc: undefined,
        resultats_header: messagesEspaceCandidat.RESULTAT_HEADER_ADMISSION
    },
    "PRE ADMISSIBILITE": {
        nom: messagesEspaceCandidat.PREADMISSIBILITE,
        desc: undefined,
        resultats_header: messagesEspaceCandidat.RESULTAT_HEADER_PREADMISSIBILITE
    },
    "PRE ADMISSION": {
        nom: messagesEspaceCandidat.PREADMISSION,
        desc: undefined,
        resultats_header: messagesEspaceCandidat.RESULTAT_HEADER_PREADMISSION
    }
};