export const messagesEspaceCandidat = {
    PAGE_TITLE: "Espace candidat",
    NOTIFICATIONS: "Mes notifications",
    CANDIDATURES: "Mes candidatures aux concours",
    NO_NOTIFS: "Aucune notification",
    NO_CANDIDATURE: "Aucune candidature",
    CREATION_COMPTE: "Création d'espace candidat",
    CREER_COMPTE: "Créer mon espace candidat",
    CREATION_SUCCES_TITLE: "Espace candidat créé",
    CREATION_SUCCES_BODY:
        "Votre espace candidat a été créé, vous pouvez désormais vous y connecter avec l'email et le mot de passe que vous avez renseigné dans le formulaire.",
    EMAIL_PRIS: "Cet email est déjà utilisé.",
    FORM_INVALIDE: "Formulaire invalide",
    INSCRIPTION: "Phase d'inscription",
    ADMISSIBILITE: "Admissibilité",
    RATTACHEMENT: "Rattachement d'un ancien compte",
    RATTACHEMENT_LOGIN: "Rattachement d'un compte via son pseudo et mot de passe",
    RATTACHEMENT_EMAIL: "Rattachement d'un ou plusieurs comptes liés à un email",
    RATTACHER: "Rattacher",
    FERMER: "Fermer",
    EXPLIC_RATTACH_LOGIN: "Entrez le pseudo et le mot de passe de votre ancien compte pour rattacher vos anciennes candidatures en cours à ce compte.",
    EXPLIC_RATTACH_EMAIL: "Entrez l'email de votre ancien compte. Si l'adresse appartient à un ou plusieurs comptes, un lien sera envoyé à cet email afin de confirmer votre identité et toutes vos anciennes candidatures en cours seront rattachées à votre compte actuel.",
    CONFIRM_RATTACH_EMAIL_1: "Un email a été envoyé à ",
    CONFIRM_RATTACH_EMAIL_2: " afin de confirmer votre identité. Cliquez sur le lien à l'intérieur pour rattacher vos anciennes candidatures en cours à votre compte actuel.",
    CONFIRM_RATTACH_LOGIN: "Vos anciennes candidatures ont été rattachées à ce compte.",
    CANDIDATURE: "Candidature",
    AU_CONCOURS: "au concours",
    PHASE_INSCRIPTION_DESC: "Phase de validation de votre candidature",
    PHASE_ADMISSIBILITE_DESC: "Phase d'évaluation permettant d'accéder à la phase d'admission",
    PHASE_ADMISSION_DEC: "Seuls les candidats ayant obtenu, à l'épreuve d'admissibilité, un total de points déterminé par le jury ont accès aux épreuves d'admission",
    PIECES_FOURNIES: "Pièces justificatives fournies et validées",
    DEMANDE_ENVOYEE: "Demande d'inscription au concours envoyée",
    DEMANDE_ENREGISTREE: "Demande d'inscription enregistrée",
    POURSUIVRE_INSCRIPTION: "Cliquez ici pour poursuivre votre inscription",
    CLOTURE_INSCRIPTION: "Les inscriptions sont clôturées.",
    CANDIDATURE_ACCEPTEE: "Candidature transmise",
    CANDIDATURE_ATTENTE: "Candidature en attente de validation",
    CANDIDATURE_CLOSES: "Candidatures closes",
    CANDIDATURE_REFUS: "Candidature refusée",
    CANDIDATURE_ACCEPTEE_BODY: "Votre dossier a été transmis à l'administration, vous recevrez une convocation si votre candidature a été validée définitivement.",
    CANDIDATURE_ATTENTE_BODY: "Votre dossier est en cours d'examination.",
    CANDIDATURE_ATTENTE_VALIDATION_BODY: "Vous n'avez pas validé votre dossier.",
    CANDIDATURE_CLOSES_BODY: "Vous n'avez pas validé votre dossier à temps.",
    CANDIDATURE_REFUS_BODY: "Nous sommes au regret de vous annoncer que votre candidature n'a pas été retenue.",
    CONVOCATION_RECUE: "Convocation émise et détail du planning prévisionnel",
    CONVOCATION_RECUE_BODY: "Félicitations, vous êtes convoqué(e) : début des épreuves le ",
    CONVOCATION_RECUE_BODY_NO_DATE: "Félicitations, vous êtes convoqué(e) aux épreuves",
    CONVOCATION_TERMINEE: "Vous avez été convoqué(e) le ",
    CONVOCATION_TERMINEE_NO_DATE: "Vous avez été convoqué(e) aux épreuves",
    EPREUVE: " épreuve",
    EPREUVES: "Epreuves",
    EPREUVES_NO_INFO: "Les informations concernant les épreuves ne sont pas disponibles pour l'instant.",
    RESULTAT_HEADER_ADMISSIBILITE: "Résultat de la phase d'admissibilité",
    RESULTAT_ETAPE_PENDING: "Résultats non disponibles pour ce recrutement.",
    RESULTAT_ETAPE_HIDDEN: "Résultats indisponibles.",
    RESULTAT_ETAPE_OK: "Félicitations, vous êtes qualifié(e) pour l’étape suivante.",
    RESULTAT_ETAPE_OK_LAST: "Félicitations, vous êtes admis(e) au concours.",
    RESULTAT_ETAPE_OK_LAST_COMP: "Vous êtes inscrit(e) en liste complémentaire pour ce concours.",
    RESULTAT_ETAPE_KO: "Nous sommes au regret de vous apprendre que vous êtes éliminé(e) du concours.",
    RESULTAT_HEADER_ADMISSION: "Résultat de la phase d'admission",
    RESULTAT_HEADER_PREADMISSIBILITE: "Résultat de la phase de pré-admissibilité",
    RESULTAT_HEADER_PREADMISSION: "Résultat de la phase de pré-admission",
    RESULTAT_HEADER_ETAPE_INCONNU: "Resultat de la phase ",
    DATE_PREFIXE: "Le ",
    NOTIF_EPREUVE_BIENTOT: "Votre prochaine épreuve est le $dateEpreuve, n'oubliez pas vos pièces d'identité",
    NOTIF_DOSSIER_TRANSMIS: "Votre demande de candidature au recrutement de : $GRADE a été transmise à l'administration. Elle sera examinée par le service gestionnaire du concours.",
    NOTIF_DOSSIER_REFUS: "Votre demande de candidature au recrutement de : $GRADE a été rejetée.",
    NOTIF_CONVOCATION_RECUE: "Vous avez reçu une nouvelle convocation concernant votre candidature au recrutement de : $GRADE.",
    NOTIF_PIECE_ADMIN: "Vous avez reçu une nouvelle demande de document concernant votre candidature au recrutement de : $GRADE.",
    NOTIF_RESULTAT_DISPO: "Le résultat de l'étape : $ETAPE relatif à votre candidature au recrutement de : $GRADE est disponible.",
    ADMISSION: "Admission",
    PREADMISSION: "Pré-admission",
    PREADMISSIBILITE: "Pré-admissibilité",
    ENCOURS: "EN COURS",
    CLOSE: "CLOSE",
    SESSION_LEGACY_MIGRATION: "Cette session est en cours de migration, nous ne sommes pas en mesure d'afficher ses informations pour le moment.",
    DEMANDE_PIECE_ADMIN_HEADER: "Demande de pièces administratives",
    DEMANDE_PIECE_ADMIN_SEND: "Téléverser",
    CONSULTER_MODELE: "Consulter le modèle",
    CONSULTER_DOCUMENT: "Consulter le document envoyé",
    BROWSE: "Parcourir",
    PIECE_ADMIN_MAXSIZE_ERROR: "Vous ne pouvez pas téléverser des fichiers ayant une taille supérieure à $size Mo",
    PIECE_ADMIN_FILE_REJECTED: "Vous n'êtes pas autorisé à téléverser ce type de fichier",
    DOWNLOAD_CONVOCATION_ARIA: "Télécharger la convocation de l'étape",
    DOWNLOAD_CONVOCATION: "Télécharger la convocation",
    DOWNLOAD_RELEVE_ARIA: "Télécharger le relevé de notes de l'étape",
    DOWNLOAD_RELEVE: "Télécharger le relevé de notes",
    FROM: "Du ",
    TO: " au ",
    ADRESSE: "Adresse",
    TYPE_EPREUVE: "Type de l'épreuve",
    TYPE_EPREUVE_SHORT: "Type",
    DATE_EPREUVE: "Date de l'épreuve",
    DATE_EPREUVE_SHORT: "Date",
    NOM_EPREUVE: "Nom de l'épreuve",
    NOM_EPREUVE_SHORT: "Nom",
    DUREE_EPREUVE: "Durée de l'épreuve",
    DUREE_EPREUVE_SHORT: "Durée",
    COEFFICIENT_EPREUVE: "Coefficient de l'épreuve",
    COEFFICIENT_EPREUVE_SHORT: "Coefficient",
    NOTE_AVIS: "Note/avis",
    ELIMINE: "Eliminé",
    FAVORABLE: "Favorable",
    DEFAVORABLE: "Défavorable"
};
