import { Box, Tab, Tabs, Text } from "grommet";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";

export const FormulaireTabs = (props: any) => {
    return (
        <Box gap="small">
            <Tabs justify="start" onActive={props.onTabChange}>
                <Tab
                    title={
                        props.etatCivilValide ? (
                            messagesInscriptionSession.ETAT_CIVIL
                        ) : (
                            <Text color="red">
                                {messagesInscriptionSession.ETAT_CIVIL} *
                            </Text>
                        )
                    }
                />
                <Box
                    style={{
                        display: props.displayAutresRens ? "flex" : "none",
                    }}
                >
                    <Tab
                        title={
                            props.autresRensValide ? (
                                messagesInscriptionSession.AUTRE_RENS
                            ) : (
                                <Text color="red">
                                    {messagesInscriptionSession.AUTRE_RENS} *
                                </Text>
                            )
                        }
                    />
                </Box>
                <Box
                    style={{
                        display: "none",
                    }}
                >
                    <Tab title={messagesInscriptionSession.COMPETENCES} />
                </Box>
                <Box
                    style={{
                        display: props.displayOptions ? "flex" : "none",
                    }}
                >
                    <Tab
                        title={
                            props.optionsValide ? (
                                messagesInscriptionSession.OPTIONS
                            ) : (
                                <Text color="red">
                                    {messagesInscriptionSession.OPTIONS} *
                                </Text>
                            )
                        }
                    />
                </Box>
                <Box
                    style={{
                        display: props.displayPiecesJointes ? "flex" : "none",
                    }}
                >
                    <Tab
                        title={
                            props.piecesJointesValide ? (
                                messagesInscriptionSession.PIECES_A_JOINDRE
                            ) : (
                                <Text color="red">
                                    {
                                        messagesInscriptionSession.PIECES_A_JOINDRE
                                    }{" "}
                                    *
                                </Text>
                            )
                        }
                    />
                </Box>
            </Tabs>
        </Box>
    );
};
