// @ts-nocheck
// Obligatoire pour pouvoir ajouter les tags Eulerian sans faire crasher la compilation
import { Box, Grommet, Main } from "grommet";
import { useContext, useEffect, useState } from "react";

import { FooterSicmi } from "../common/FooterSicmi";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { paths } from "../paths";
import { theme } from "../theme";
import { FormulaireInscription } from "./FormulaireInscription";
import { SessionInfo } from "./SessionInfo";
import { ErrorBody } from "../error/ErrorBody";
import { agentInscriptions } from "./agentInscriptions";
import { ErrorDispatch } from "../App";
import { SessionsIncompatiblesModal } from "./SessionsIncompatiblesModal";

export const InscriptionSession = (props) => {
    const errorDispatch = useContext(ErrorDispatch);

    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Inscription à une session" },
    ];

    const [inscriptionStatut, setInscriptionStatut] = useState(undefined);
    const [modalDismissed, setModalDismissed] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [session, setSession] = useState(undefined);

    useEffect(() => {
        if (!inscriptionStatut) {
            agentInscriptions.getAutorisationInscription(
                props.params.idSession,
                (response) => {
                    if (
                        response.inscriptionAutorisee === true ||
                        response.sessionsIncompat?.length > 0
                    ) {
                        setInscriptionStatut(response);
                        agentInscriptions.getSession((result) => {
                            setSession(result);
                        }, props.params.idSession);
                    } else {
                        setErrorMessage(
                            messagesInscriptionSession.INSCRIPTION_NON_AUTORISEE
                        );
                    }
                },
                (error) => {
                    if (error === "409") {
                        setErrorMessage(
                            messagesInscriptionSession.INSCRIPTION_DEJA_VALIDE
                        );
                    } else {
                        errorDispatch({ type: error });
                    }
                }
            );
        }

        if (typeof window["EA_push"] === "function") {
            EA_push([
                "path", "/inscription",
                "pagelabel", "Formulaire Inscription",
            ]);
        }
    }, [errorDispatch, inscriptionStatut, props.params.idSession]);

    const dismissModal = () => {
        setModalDismissed(true);
    };

    const canDisplayFormulaire = () => {
        return (
            !errorMessage &&
            inscriptionStatut?.inscriptionAutorisee === true &&
            (modalDismissed || inscriptionStatut?.sessionsIncompat === null) &&
            inscriptionStatut?.sessionsConflit === null
        );
    };

    return (
        <Grommet full theme={theme}>
            <Box fill={inscriptionStatut ? false : "vertical"}>
                <HeaderSicmi
                    title={messagesInscriptionSession.PAGE_TITLE}
                    breadcrumbs={breadcrumbs}
                />
                <Main>
                    {canDisplayFormulaire() && (
                        <Box flex overflow="auto">
                            <SessionInfo session={session} />
                            <FormulaireInscription
                                idSession={props.params.idSession}
                                messagePj={session?.pieceJointeMsgExplicatif}
                                noAcquisition={session?.sansAcquisitionEnCours}
                            />
                        </Box>
                    )}
                    {!errorMessage &&
                        !modalDismissed &&
                        (inscriptionStatut?.sessionsIncompat?.length > 0 ||
                            inscriptionStatut?.sessionsConflit?.length > 0) && (
                            <SessionsIncompatiblesModal
                                libelleSession={
                                    inscriptionStatut.sessionLibelle
                                }
                                sessionsIncompat={
                                    inscriptionStatut.sessionsIncompat
                                }
                                sessionsConflit={
                                    inscriptionStatut.sessionsConflit
                                }
                                onClose={dismissModal}
                            />
                        )}
                    {errorMessage && (
                        <ErrorBody
                            text={errorMessage}
                            returnPath={paths.LISTE_SESSIONS}
                            returnLabel={
                                messagesInscriptionSession.BACK_SESSIONS
                            }
                        />
                    )}
                </Main>
                {canDisplayFormulaire() && <FooterSicmi />}
            </Box>
        </Grommet>
    );
};

export const prepareOptionsForSave = (
    formulaire,
    refEpreuvesObligatoires,
    refEpreuvesFacultatives
) => {
    let eprObl = Object.entries(formulaire)
        .filter(
            (object) =>
                object[0].startsWith("eprObl") === true &&
                object[1] &&
                typeof object[1] !== "boolean"
        )
        .map((object) => {
            return object[1];
        });

    let eprFal = Object.entries(formulaire)
        .filter(
            (object) =>
                object[0].startsWith("eprFal") === true &&
                object[1] !== "" &&
                (object[1] === true || typeof object[1] !== "boolean")
        )
        .map((object) => {
            if (typeof object[1] === "boolean") {
                return parseInt(object[0].replace("eprFal", ""));
            }
            return object[1];
        });

    formulaire.choixVoeux = Object.entries(formulaire)
        .filter((object) => object[0].startsWith("voeu") === true && object[1])
        .map((object) => {
            const ordre = object[0].replace("voeu", "");
            const voeu = object[1];
            voeu.ordre = ordre-1;
            return voeu;
        });

    formulaire.epreuvesFacultatives = [];
    if (
        eprFal &&
        eprFal.length > 0 &&
        refEpreuvesFacultatives &&
        refEpreuvesFacultatives !== "absent"
    ) {
        formulaire.epreuvesFacultatives = eprFal.map((fal: any) => {
            let result = refEpreuvesFacultatives.find(
                (epr) =>
                    epr.tepreuveId === (fal.tepreuveId ? fal.tepreuveId : fal)
            );
            if (fal.toptionEpreuveId) {
                result.selectedOptionId = fal.toptionEpreuveId;
            }
            return result;
        });
    }

    // eprObl.forEach((epr) => delete formulaire["eprObl" + epr.tepreuveId]);
    formulaire.epreuvesObligatoires = [];
    if (
        eprObl &&
        eprObl.length > 0 &&
        refEpreuvesObligatoires &&
        refEpreuvesObligatoires !== "absent"
    ) {
        formulaire.epreuvesObligatoires = eprObl.map((obl: any) => {
            let result = refEpreuvesObligatoires.find(
                (epr) => epr.tepreuveId === obl.tepreuveId
            );
            result.selectedOptionId = obl.toptionEpreuveId;
            return result;
        });
    }

    return formulaire;
};
