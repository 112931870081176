import { Box, Button, Text } from "grommet";
import { StatusGood } from "grommet-icons";
import { messagesCommon } from "../messages/messagesCommon";

export const ModalSuccess = (props) => {
    return (
        <Box gap="medium" align="center">
            <StatusGood size="xlarge" color="limegreen" />
            <Text>
                {props.message}
            </Text>

            <Button
                primary
                label={messagesCommon.FERMER}
                onClick={() => props.onClose()}
            />
        </Box>
    );
};
