import { paths } from "../paths";
import { store } from "../auth/store";
import { FetchHandler } from "../auth/fetchHandler";
import { injectAuthHeader, injectCsrfHeader } from "../auth/header";

export const agentCandidat = {
    creerCompte: (form, callback) => {
        fetch(paths.API_COMPTE_CREATION, {
            method: "POST",
            headers: injectCsrfHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(form),
        })
            .then((response) => {
                return response;
            })
            .then((data) => {
                callback(data);
            });
    },
    getCandidatures: (callback: Function, errorCallback: Function) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_CANDIDAT_CANDIDATURES,
            callback,
            errorCallback
        );
    },
    rattacherCompteLogin: (login: string, password: string, callback) => {
        const postData = {
            "rattachementPseudo": login,
            "rattachementPassword": password,
        };
        
        const formBody = Object.keys(postData)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(postData[key]))
            .join('&');
        
        fetch(paths.API_COMPTE_RATTACHER_LOGIN, {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({'Content-Type': 'application/x-www-form-urlencoded'})),
            body: formBody,
        })
            .then((response) => {
                return response;
            })
            .then((result) => {
                callback(result);
            });
    },
    rattacherCompteEmail: (email, callback) => {
        const paramsString = "rattachementEmail=" + email;
        const data = new URLSearchParams(paramsString);
        fetch(paths.API_COMPTE_RATTACHER_EMAIL, {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({})),
            body: data,
        })
            .then((response) => {
                return response;
            })
            .then((result) => {
                callback(result);
            });
    },
    confirmerRattachementEmail: (token, callback) => {
        const paramsString = "token=" + token;
        const data = new URLSearchParams(paramsString);
        fetch(paths.API_COMPTE_RATTACHER_EMAIL_CONFIRMATION, {
            method: "POST",
            headers: injectCsrfHeader({}),
            body: data,
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                callback(result);
            })
            .catch((err) => callback(err));
    },
    encodeParams: (params) => {
        return Object.keys(params)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(params[key])
            )
            .join("&");
    },
    uploadPieces: (form, callback) => {
        fetch(paths.API_PIECE_ADMIN_UPLOAD, {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({})),
            body: form
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.ok;
        })
        .then((data) => {
            callback(data);
        }).catch((error) => {
            console.log(error.cause, error.message);
            callback(error.message);
        });
    },
    getPieceAdminConfig: (callback) => {
        const authToken =
            store.connected === true ? "Bearer " + store.token : "";
        fetch(paths.API_PIECE_ADMIN_CONFIG, {
            method: "GET",
            headers: {
                Authorization: authToken,
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.json();
        })
        .then((data) => {
            callback(data);
        }).catch((error) => {
            console.log(error.cause, error.message);
        });
    }
};
