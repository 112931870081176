import L from "leaflet";

import { fetchWithHeaders } from "./fetchWithHeaders";

/**
 * Extension de Leaflet pour pouvoir passer des headers HTTP
 * Dans notre cas, nous avons besoin de passer une Basic Auth
 */
L.TileLayer.WMSHeader = L.TileLayer.WMS.extend({
    initialize: function (url, options, headers) {
      L.TileLayer.WMS.prototype.initialize.call(this, url, options);
      this.headers = headers;
    },
    createTile(coords, done) {
      const url = this.getTileUrl(coords);
      const img: HTMLImageElement = document.createElement("img");
      img.setAttribute("role", "presentation");
  
      fetchWithHeaders(
        url,
        resp => {
          const reader = new FileReader();
          reader.onload = () => {
            img.src = reader.result.toString();
          };
          reader.readAsDataURL(resp);
          done(null, img);
        },
        this.headers
      );
      return img;
    }
});
  
L.TileLayer.wmsHeader = function (url, options, headers) {
    return new L.TileLayer.WMSHeader(url, options, headers);
};

/**
 * On étend Leaflet et on renomme L pour bien le montrer
 */
export { L as LExtended };
