import { Box } from "grommet";

import { VoeuxAffectation } from "./VoeuxAffectation";
import { ChoixEpreuves } from "./ChoixEpreuves";

export const Options = (props) => {
    return (
        <Box border pad="small" gap="large">
            <ChoixEpreuves formulaire={props.formulaire} eprObl={props.eprObl} eprFal={props.eprFal} />
            <VoeuxAffectation formulaire={props.formulaire} paramVoeux={props.paramVoeux} voeuxPossibles={props.voeuxPossibles} />
        </Box>
    );
};
