import { FormField, Select } from "grommet";

import { messagesListeSessions } from "../messages/messagesListeSessions";

export const CritereSelect = (props: any) => {
    
    return (
        <FormField
            label={props.label}
            name={props.name}
        >
            <Select
                name={props.name}
                labelKey={props.labelKey}
                valueKey={{
                    key: props.valueKey,
                    reduce: true,
                }}
                options={props.options}
                value={props.value}
                onChange={({value}) =>
                    props.updateFilters(props.name, value)
                }
                replace
                dropHeight="medium"
                placeholder={messagesListeSessions.SELECT}
                clear={props.clear !== false ? {
                    position: "top",
                    label: messagesListeSessions.CLEAR,
                } : false}
            />
        </FormField>
    );
};
