import { Text } from "grommet";
import { useEffect, useState } from "react";
import { RoutedAnchor } from "../anchor/RoutedAnchor";

import { store } from "../auth/store";

import { messagesListeSessions } from "../messages/messagesListeSessions";

export const BoutonInscriptionResponsive = (props) => {
    
    const [canRegister, setCanRegister] = useState(false);

    useEffect(() => {
        let currentDate = new Date().getTime();
        let dateDebut = Date.parse(props.session.ouvertureInscriptionInternet);
        let dateFin = Date.parse(props.session.limiteDepotDossierInternet);

        if (currentDate >= dateDebut && currentDate <= dateFin && props.session.autoriserCandidatureInternet) {
            setCanRegister(true);
        }
    }, [props]);

    return (
        store.connected && canRegister ? (
                <RoutedAnchor to={`/inscription/${props.session.tsessionId}`} label={messagesListeSessions.INSCRIRE} />
        ) : (
                <Text>
                    {
                            canRegister
                                ? messagesListeSessions.NEED_ACCOUNT
                                : messagesListeSessions.NO_INSCR
                        }
                </Text>
        )
    );
};
