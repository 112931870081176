import { Box, Button, Form, MaskedInput, Text } from "grommet";
import { MailOption } from "grommet-icons";

import { useState } from "react";
import { messagesCommon } from "../messages/messagesCommon";

export const EmailForm = (props) => {

    const [email, setEmail] = useState('');

    function onSubmit() {
        props.onEmailSubmit(email);
    }

    return (
        <Box fill align="center">
            <Box
                pad="medium"
                border={{ color: "brand", size: "medium" }}
                round
                direction="column"
                gap="small"
                elevation="xlarge"
                align="center"
                justify="center"
            >
                <Text>
                {messagesCommon.FC_EMAIL_1}
                </Text>
                <Text margin={{ bottom: "medium" }}>
                    {messagesCommon.FC_EMAIL_2}
                </Text>
                <Form onSubmit={onSubmit}>
                    <Box width="medium" gap="small">
                        <MaskedInput
                            icon={<MailOption />}
                            name="emailLogin"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            mask={[
                                {
                                    regexp: /^[\w\-_.]+$/,
                                    placeholder: "exemple",
                                },
                                { fixed: "@" },
                                {
                                    regexp: /^[\w-]+$/,
                                    placeholder: "fournisseur",
                                },
                                { fixed: "." },
                                { regexp: /^[\w.]+$/, placeholder: "fr" },
                            ]}
                        />
                        <Button primary type="submit" label="Envoyer" />
                    </Box>
                </Form>
            </Box>
        </Box>
    );
};
