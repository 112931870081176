import { Box, Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { Convocation } from "./Convocation";
import { BlocEpreuves } from "./BlocEpreuves";
import { Resultats } from "./Resultats";
import { VerticalDivider } from "../components/VerticalDivider";
import { getTheme } from "../etapeThemes";
import { listeEtapes } from "./listeEtapes";
import { useEffect, useState } from "react";
import { useResponsive } from "../../responsive";
import { getNextEpreuve } from "../epreuvesHelper";

export const Etape = (props) => {
    const etapeTheme = (() => {
        switch (props.etape.etat) {
            case "encours":
                return getTheme(
                    "ok",
                    messagesEspaceCandidat.ENCOURS,
                    undefined
                );
            case "close":
                return getTheme(
                    "doneOK",
                    messagesEspaceCandidat.CLOSE,
                    undefined
                );
            default:
                return getTheme("pending", undefined, undefined);
        }
    })();

    const [etapeInfo, setEtapeInfo] = useState({
        nom: undefined,
        desc: undefined,
        resultats_header: undefined,
    });

    useEffect(() => {
        setEtapeInfo(
            listeEtapes[props.etape.nomEtape] !== undefined
                ? listeEtapes[props.etape.nomEtape]
                : {
                      nom: props.etape.nomEtape,
                      desc: undefined,
                      resultats_header:
                          messagesEspaceCandidat.RESULTAT_HEADER_ETAPE_INCONNU +
                          props.etape.nomEtape,
                  }
        );
    }, [props.etape]);

    const smallTheme = {
        border: undefined,
    };

    const regularTheme = {
        border: { size: "small" },
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    const nextEpreuve = getNextEpreuve(props.etape.epreuves);

    return (
        <Box gap="small">
            <Box
                pad={{left:"medium", right:"small"}}
                border={responsiveTheme.border}
                round="medium"
                direction="row"
            >
                <Box direction="column" fill="horizontal">
                    <Box direction="row" gap="small">
                        <VerticalDivider
                            background={{
                                color: etapeTheme.theme.dividerColor,
                                doted: etapeTheme.theme.doted,
                            }}
                        />
                        <Box pad={{top:"small"}} direction="row" height="xxsmall" gap="medium">
                            <Text
                                textAlign="start"
                                size="xxlarge"
                                color={etapeTheme.theme.backgroundColor}
                                weight="bold"
                            >
                                {etapeInfo.nom}
                            </Text>
                            {etapeTheme.header && (
                                <Box alignSelf="center">
                                    <Box
                                        background={
                                            etapeTheme.theme.backgroundColor
                                        }
                                        round="small"
                                        pad="xsmall"
                                    >
                                        <Text
                                            color="white"
                                            weight="bold"
                                            size="xsmall"
                                        >
                                            {etapeTheme.header}
                                        </Text>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Convocation hasNextEpreuve={nextEpreuve !== undefined} etape={props.etape} idSession={props.idSession} idDossier={props.idDossier} />
                    <BlocEpreuves
                        mapIndex={props.mapIndex * 10 + 1}
                        etape={props.etape}
                        nextEpreuve={nextEpreuve}
                    />
                    <Resultats
                        header={etapeInfo.resultats_header}
                        etape={props.etape}
                        idSession={props.idSession} 
                        idDossier={props.idDossier}
                    />
                </Box>
            </Box>
        </Box>
    );
};
