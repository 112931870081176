import { Box, Button, Form, MaskedInput, Text } from "grommet";
import { useState } from "react";
import { StatusGood } from "grommet-icons";

import { messagesEspaceCandidat } from "../messages/messagesEspaceCandidat";
import { agentCandidat } from "./agentCandidat";

export const RattachementEmail = () => {
    const [email, setEmail] = useState(false);

    const onSubmit = (e) => {

        if (e.value.rattachementEmail) {
            agentCandidat.rattacherCompteEmail(e.value.rattachementEmail, (result) => {
                setEmail(e.value.rattachementEmail);
            });
        }
    };

    return (
        <Box
            alignSelf="center"
            margin={{ top: "small" }}
            gap="medium"
            width="70%"
        >
            {email === false ? (
                <Box gap="medium">
                    <Text>{messagesEspaceCandidat.EXPLIC_RATTACH_EMAIL}</Text>
                    <Form onSubmit={onSubmit}>
                        <MaskedInput
                            name="rattachementEmail"
                            mask={[
                                {
                                    regexp: /^[\w\-_.]+$/,
                                    placeholder: "exemple",
                                },
                                { fixed: "@" },
                                {
                                    regexp: /^[\w-]+$/,
                                    placeholder: "fournisseur",
                                },
                                { fixed: "." },
                                { regexp: /^[\w.]+$/, placeholder: "fr" },
                            ]}
                        />
                        <Box>
                            <Button
                                type="submit"
                                label={messagesEspaceCandidat.RATTACHER}
                                primary
                                margin={{ top: "small" }}
                            />
                        </Box>
                    </Form>
                </Box>
            ) : (
                <Box gap="medium" align="center">
                    
                    <StatusGood size="xlarge" color="limegreen" />
                    <Text>
                        {messagesEspaceCandidat.CONFIRM_RATTACH_EMAIL_1}<strong>{email}</strong>
                        {messagesEspaceCandidat.CONFIRM_RATTACH_EMAIL_2}
                    </Text>
                </Box>
            )}
        </Box>
    );
};
