import { FormField, TextInput, Text } from "grommet";
import { useState } from "react";
import { messagesInscriptionSession } from "../../messages/messagesInscriptionSession";

import { FormFieldLabel } from "./FormFieldLabel";

export const DateFormField = (props) => {
    const {
        label,
        italicLabel,
        required,
        name,
        placeholder,
        birthdate,
        validation,
        ageMin,
        ageMax,
        ...rest
    } = props;

    const [warning, setWarning] = useState(undefined);

    function validateBirthDate(value: string) {
        if (value) {
            let date = new Date(value);
            let currentDate = new Date();
            let dateUtc = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
            );
            let currentDateUtc = new Date(
                currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate()
            );
            if (dateUtc.getTime() > currentDateUtc.getTime()) {
                return messagesInscriptionSession.BIRTHDATE_ERROR;
            }

            if (ageMin !== undefined || ageMax !== undefined) {
                let ageDiff = Date.now() - date.getTime();
                let ageDate = new Date(ageDiff); // miliseconds from epoch
                let age = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (
                    (ageMin > 0 && age < ageMin) ||
                    (ageMax > 0 && age > ageMax)
                ) {
                    return messagesInscriptionSession.BIRTHDATE_ERROR;
                }
            }
        }
    }

    // Parce que pour le moment, mettre un input date dans un formfield Grommet fait
    // que le champ n'est visuellement pas vidé si la date est invalide à la sortie du champ
    // Mais la valeur dans le formulaire est bien "". Pour pouvoir remonter cette information
    // au candidat pour les dates non obligatoires (et donc qui auront une valeur vide alors qu'ils verront bien une date dans leur champ)
    // on applique un message d'information en orange pour indiquer l'invalidité de la date. Comme ca cela ne bloque pas la validation du formulaire
    function validateDate(value: string) {
        if (value === "" && !required) {
            setWarning(
                <Text weight="bold" color="sicmiorange">
                    {messagesInscriptionSession.DATE_INVALID}
                </Text>
            );
        } else if (warning) {
            setWarning(undefined);
        }
    }

    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            a11yTitle={
                label + required ? " (Obligatoire pour soumission)" : undefined
            }
            validate={birthdate ? validateBirthDate : validateDate}
            htmlFor={name}
            info={warning}
            {...rest}
        >
            <TextInput
                id={name}
                name={name}
                placeholder={placeholder}
                type="date"
            />
        </FormField>
    );
};
