import { paths } from "../paths";
import { store } from "../auth/store";
import { FetchHandler } from "../auth/fetchHandler";
import { injectAuthHeader, injectCsrfHeader } from "../auth/header";

export const agentInscriptions = {
    getAutresRenseignementsChamps: (
        sessionId,
        callback: Function,
        errorCallback: Function
    ) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_ONGLET_INTERNET_SESSION.replace(":idSession", sessionId),
            callback,
            errorCallback
        );
    },
    getCompetencesChamps: (
        sessionId,
        callback: Function,
        errorCallback: Function
    ) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_ONGLET_COMPETENCES_SESSION.replace(
                ":idSession",
                sessionId
            ),
            callback,
            errorCallback
        );
    },
    getInscriptionReferentiel: (
        callback: Function,
        errorCallback: Function,
        sessionId: any
    ) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_INSCRIPTION_REFERENTIEL + "/" + sessionId,
            callback,
            errorCallback
        );
    },
    getInscriptionDroits: (callback: Function, errorCallback: Function) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_INSCRIPTION_DROITS,
            callback,
            errorCallback
        );
    },
    getAutorisationInscription: (sessionId: any, callback: Function, errorCallback: Function) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_AUTORISATION_INSCRIPTION.replace(":idSession", sessionId),
            callback, errorCallback
        );
    },
    getPostValidationContent: (sessionId: any, callback: Function, errorCallback: Function) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_INSCRIPTION_POST_VALIDATION.replace(":idSession", sessionId),
            callback, errorCallback
        );
    },
    getUploadedPiecesJointes: (idDossier: any, callback: Function, errorCallback: Function) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_PIECE_JOINTE_LIST.replace(":idDossier", idDossier),
            callback,
            errorCallback
        );
    },
    supprimerDossier: (
        callback: Function,
        errorCallback: Function,
        sessionId: any,
    ) => {
        fetch(
            paths.API_INSCRIPTION_SUPPRIMER.replace(":idSession", sessionId),
            {
                method: "POST",
                headers: injectCsrfHeader(injectAuthHeader({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                })),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(String(response.status));
                }
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((err) => {
                errorCallback(err.message);
            });
    },
    enregistrerDossier: (
        callback: Function,
        errorCallback: Function,
        sessionId: any,
        formulaire: any
    ) => {
        fetch(
            paths.API_INSCRIPTION_ENREGISTRER.replace(":idSession", sessionId),
            {
                method: "POST",
                headers: injectCsrfHeader(injectAuthHeader({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                })),
                body: JSON.stringify(formulaire),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(String(response.status));
                }
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((err) => {
                errorCallback(err.message);
            });
    },
    validerDossier: (sessionId: any, idBurRecrut: any, callback: Function, errorCallback: Function) => {
        let params = undefined;
        if (idBurRecrut) {
            params = encodeURIComponent("idBurRecrut") + '=' + encodeURIComponent(idBurRecrut);
        }
        
        fetch(paths.API_INSCRIPTION_VALIDER.replace(":idSession", sessionId), {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            })),
            body: params
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.json();
        })
        .then((data) => {
            callback(data);
        })
        .catch((err) => {
            errorCallback(err.message);
        });
    },
    getDossier: (
        sessionId: any,
        callback: Function,
        errorCallback: Function
    ) => {
        FetchHandler.getJsonForLoggedUser(
            paths.API_INSCRIPTION_GET_DOSSIER.replace(":idSession", sessionId),
            callback,
            errorCallback
        );
    },
    getSession: (callback: Function, sessionId: any) => {
        fetch(paths.API_SESSION + "/" + sessionId)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                callback(data);
            });
    },
    uploadPiece: (form, callback) => {
        fetch(paths.API_PIECE_JOINTE_UPLOAD, {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({})),
            body: form
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.ok;
        })
        .then((data) => {
            callback(data);
        }).catch((error) => {
            callback(error.message);
        });
    },
    deletePiece: (idDossier, idPiece, callback) => {
        fetch(paths.API_PIECE_JOINTE_DELETE.replace(":idDossier", idDossier).replace(":idPieceJointe", idPiece), {
            method: "POST",
            headers: injectCsrfHeader(injectAuthHeader({})),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.ok;
        })
        .then((data) => {
            callback(data);
        }).catch((error) => {
            callback(error.message);
        });
    },
    getInscriptionLabels: (callback, idSession) => {
        fetch(paths.API_LABELS_INSCRIPTION.replace(":idSession", idSession))
        .then((response) => {
            return response.json();
        }, error => {
            console.log(error);
        })
        .then((data) => {
            callback(data);
        })
    },
};
