import { CheckBox, FormField } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const CheckboxFormField = (props) => {
    const { label, required, name, options, value, italicLabel, onChange, clear, ...rest } = props;

    return (
        <FormField
            label={<FormFieldLabel label={label} required={required} italicLabel={italicLabel} />}
            required={required}
            name={name}
            htmlFor={name}
            a11yTitle={label}
            {...rest}
        >
            <CheckBox
                name={name}
                id={name}
                value={value}
            />
        </FormField>
    );
};
