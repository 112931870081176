import { FormField, MaskedInput } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const PhoneFormField = (props) => {
    const { label, italicLabel, required, name, length, ...rest } = props;
    const finalValidate = required
        ? {
              regexp: /^\+?[0-9]{4,20}$/,
              message: "Le numéro de téléphone doit avoir 4 à 20 chiffres, l'indicatif +XX est optionnel",
          }
        : {
            regexp: /^(?:\+?[0-9]{4,20})?$/,
            message: "Le numéro de téléphone doit avoir 4 à 20 chiffres, l'indicatif +XX est optionnel",
        };
    const finalRegex = /^\+?[0-9]{0,20}$/;
    return (
        <FormField
            label={<FormFieldLabel label={label} required={required} italicLabel={italicLabel} />}
            required={required}
            name={name}
            validate={finalValidate}
            {...rest}
        >
            <MaskedInput
                mask={[
                    {
                        regexp: finalRegex,
                    },
                ]}
                name={name}
            />
        </FormField>
    );
};
